import React, { Component } from "react";
import axios from 'axios'
import { Input, Button, Form, Modal, Card, Col, Row, message,Image } from "antd";
import { strings } from 'res';

export class Tampilan extends Component {
    constructor(props){
        super(props)
        this.state = {
          homePageInd:{},
          homePageEng:{},
          visible: false,
          updateMessage:"",
          upload:{
            name: 'file',
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            headers: {
              authorization: 'authorization-text',
            },
            maxSize:1000000,
            onChange(info) {
              if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
              }
              if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            }
          }
        }
        this.handleChange = this.handleChange.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.showModal = this.showModal.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleOk = this.handleOk.bind(this);
      }
    
      showModal = () => {
        this.setState({
          visible: true,
        });
      };
    
      handleCancel = e => {
        console.log(e);
        this.setState({
          visible: false,
        });
      };
    
      handleOk = e => {
        console.log(e);
        this.setState({
          visible: false,
        });
      };
    
      componentDidMount(){
        axios.get(`${strings.url.content_url}/homePage/read`, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin":"*"
          }
        }).then(doc=>{
            this.setState({
                homePageInd:doc.data.HomeInd[0]
              })
        })
        axios.get(`${strings.url.content_url}/homePage/read`, {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin":"*"
            }
          }).then(doc=>{
              this.setState({
                  homePageEng:doc.data.HomeEng[0]
                })
          })
      }
    
      handleChange(event){
        this.setState({
          homePageInd:{
            ...this.state.homePageInd,
            [event.target.name]: event.target.value
           },
           homePageEng:{
            ...this.state.homePageEng,
            [event.target.name]: event.target.value
           },
        })
        console.log(this.state.homePageInd)
      }
    
       onFinish = (values) =>{
        axios.post(`${strings.url.content_url}/homePage/updateIndonesia`,this.state.homePageInd, {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin":"*"
            }
          }).then(doc=>{
            console.log(doc)
            this.setState({
              visible: true,
              updateMessage:doc.data.msg
            });
          })
          axios.post(`${strings.url.content_url}/homePage/updateEnglish`,this.state.homePageEng, {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin":"*"
            }
          }).then(doc=>{
            console.log(doc)
            this.setState({
              visible: true,
              updateMessage:doc.data.msg
            });
          })
      }

    render() {
        return (
            <div >
                  <Modal
                        title="Message!"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                    >
                        <h3 style={{textAlign:"center"}}>{this.state.updateMessage}</h3>
                    </Modal>
                <h1 style={{ textAlign: "center" }}>Home Page Structure</h1>
                <Card style={{ height: "100%" }}>
                    <Card>
                        <Row type="flex">
                            <h3 style={{ textAlign: "center" }}>Navigation</h3>
                        </Row>
                    </Card>
                    <Card style={{ textAlign: "center" }} type="flex">
                        Heading - 1, Heading - 2, Heading 3
                    </Card>
                    <Card>
                        <Row style={{ textAlign: "center" }}>
                            <h5 style={{ textAlign: "center" }}>Heading Description</h5>
                        </Row>
                        <Row gutter={24} type="flex">
                            <Col sm={24} md={24} lg={8}>
                                <Card type="flex">
                                    Heading - 1 <br /> Description
                                </Card>
                            </Col>
                            <Col sm={24} md={24} lg={8}>
                                <Card type="flex">
                                    Heading - 2 <br /> Description
                                </Card>
                            </Col>
                            <Col sm={24} md={24} lg={8}>
                                <Card type="flex">
                                    Heading - 3 <br /> Description
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                    <Card>
                        <Row >
                            <h5 style={{ textAlign: "center" }}>Stories Section</h5>
                        </Row>
                    </Card>
                    <Card>
                        <Row >
                            <h5 style={{ textAlign: "center" }}>Value Chains</h5>
                        </Row>
                        <Row >
                            <p style={{ textAlign: "center" }}>Value Chains Description</p>
                        </Row>
                        <Row gutter={24} type="flex">

                            <Col sm={24} md={24} lg={8}>
                                <Card>
                                    Value Chain 1 <br /> Title
                                </Card>
                            </Col>
                            <Col sm={24} md={24} lg={8}>
                                <Card>
                                    Value Chain 2 <br /> Title
                                </Card>
                            </Col>
                            <Col sm={24} md={24} lg={8}>
                                <Card>
                                    Value Chain 3 <br /> Title
                                </Card>
                            </Col>
                            <Col sm={24} md={24} lg={8}>
                                <Card>
                                    Value Chain 4 <br /> Title
                                </Card>
                            </Col>
                            <Col sm={24} md={24} lg={8}>
                                <Card>
                                    Value Chain 5 <br /> Title
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                    <Card>
                        <Row >
                            <h5 style={{ textAlign: "center" }}>Stories</h5>
                        </Row>
                        <Row >
                            <p style={{ textAlign: "center" }}>Stories Description</p>
                        </Row>
                        <Row gutter={24} type="flex">
                            <Col sm={24} md={24} lg={8}>
                                <Card>
                                    Impact
                                </Card>
                            </Col>
                            <Col sm={24} md={24} lg={8}>
                                <Card>
                                    News
                                </Card>
                            </Col>
                            <Col sm={24} md={24} lg={8}>
                                <Card>
                                    Media
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                    <Card>
                        <Row >
                            <h5 style={{ textAlign: "center" }}>Resources</h5>
                        </Row>
                        <Row >
                            <p style={{ textAlign: "center" }}>Resources Description</p>
                        </Row>
                        <Row gutter={24} type="flex">
                            <Col sm={24} md={24} lg={8}>
                                <Card>
                                    Publications
                                </Card>
                            </Col>
                            <Col sm={24} md={24} lg={8}>
                                <Card>
                                    Manuals
                                </Card>
                            </Col>
                            <Col sm={24} md={24} lg={8}>
                                <Card>
                                    Newsletters
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                    <Card>
                        <Row >
                            <h5 style={{ textAlign: "center" }}>Social Media</h5>
                        </Row>
                        <Row >
                            <p style={{ textAlign: "center" }}>Social Media Description</p>
                        </Row>
                        <Row gutter={24} type="flex">
                            <Col sm={24} md={24} lg={8}>
                                <Card>
                                    Facebook Feed
                                </Card>
                            </Col>
                            <Col sm={24} md={24} lg={8}>
                                <Card>
                                    Twitter Feed
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                    <Card>
                        <Row >
                            <h3 style={{ textAlign: "center" }}>Contact Us Section</h3>
                        </Row>
                    </Card>
                    <Card>
                        <Row >
                            <h3 style={{ textAlign: "center" }}>Stakeholders Section</h3>
                        </Row>
                    </Card>
                    <Card>
                        <Row >
                            <h3 style={{ textAlign: "center" }}>Footer</h3>
                        </Row>
                    </Card>
                </Card>
                <h1>Images</h1>
                <Card style={{ height: "100%" }}>
                <Form onFinish={this.onFinish} >  
                    <label>Jumbotron - 1</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_1} name="image_1" placeholder="Image - 1" />
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_1}></Image>
                    <br />
                    <br />
                    <label>Jumbotron - 2</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_2} name="image_2" placeholder="Image - 2" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_2}></Image>
                    <br />
                    <br />
                    <label>Jumbotron - 3</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_3} name="image_3" placeholder="Image - 3" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_3}></Image>
                    <br />
                    <br />
                    <label>About Us Image</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_4} name="image_4" placeholder="Image - 4" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_4}></Image>
                    <br />
                    <br />
                    <label>Shrimp</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_5} name="image_5" placeholder="Image - 5" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_5}></Image>
                    <br />
                    <br />
                    <label>Pengasius</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_6} name="image_6" placeholder="Image - 6" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_6}></Image>
                    <br />
                    <br />
                    <label>Seaweed</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_7} name="image_7" placeholder="Image - 7" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_7}></Image>
                    <br />
                    <br />
                    <label>Milkfish</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_8} name="image_8" placeholder="Image - 8" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_8}></Image>
                    <br />
                    <br />
                    <label>Catfish</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_9} name="image_9" placeholder="Image - 9" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_9}></Image>
                    <br />
                    <br />
                    <label>Publications</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_10} name="image_10" placeholder="Image - 10" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_10}></Image>
                    <br />
                    <br />
                    <label>Manuals</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_11} name="image_11" placeholder="Image - 11" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_11}></Image>
                    <br />
                    <br />
                    <label>Newsletters</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_12} name="image_12" placeholder="Image - 12" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_12}></Image>
                    <br />
                    <br /> 
                    {/* <label>Newsletters</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_12} name="image_12" placeholder="Image - 12" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_12}></Image>
                    <br />
                    <br /> 
                    <label>Publications</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_13} name="image_13" placeholder="Image - 13" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_13}></Image>
                    <br />
                    <br />
                    <label>Manuals</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_14} name="image_14" placeholder="Image - 14" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_14}></Image>
                    <br />
                    <br /> */}
                    <Form.Item >
                        <Button htmlType="submit" type="primary" style={{width:"100%"}} >Submit</Button>
                    </Form.Item>
                </Form>
                </Card>
            </div>
        );
    }
}

export default Tampilan;
