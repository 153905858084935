import {
  AppstoreOutlined,
  BookOutlined, CommentOutlined, EnvironmentOutlined,
  FileDoneOutlined, LayoutOutlined,MessageOutlined,
  ScheduleOutlined, FileImageOutlined, UserSwitchOutlined,NotificationOutlined,
 GlobalOutlined,CompassOutlined,DatabaseOutlined
} from '@ant-design/icons';
import { strings } from 'res';


const dashBoardNavTree = [{
  key: strings.menu.home,
  path: strings.navigation.page.home,
  title: "Analisa Data",
  icon: EnvironmentOutlined,
  breadcrumb: false,
  submenu: [{
    key: strings.menu.home,
    path: strings.navigation.page.home,
    title: strings.menu.dashboard,
    icon: LayoutOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: "Subscribers",
    path: strings.navigation.page.subscribers,
    title: "Subscribers",
    icon: UserSwitchOutlined,
    breadcrumb: false,
    submenu: [{
      key: "Analytics",
      path: strings.navigation.page.subscribers_analytic,
      title: "Analytics",
      breadcrumb: false,
      submenu: []
    },{
      key: "Subscribers",
      path: strings.navigation.page.subscribers,
      title: "Subscribers",
      breadcrumb: false,
      submenu: []
    }]
  }
]
}]

const pageNavTree = [{
  key: strings.menu.pages,
  path: strings.navigation.page.homePage,
  title: "Website GQSP",
  icon: LayoutOutlined,
  breadcrumb: false,
  submenu: [
  {
    key: strings.menu.homePage,
    path: strings.navigation.page.homePage,
    title: "6 Halaman Utama",
    icon: FileDoneOutlined,
    breadcrumb: false,
    submenu: [{
      key: strings.menu.homePage,
      path: strings.navigation.page.homePage,
      title: "Home",
      icon: FileDoneOutlined,
      breadcrumb: false,
      submenu:[]},
      {
      key: strings.menu.aboutPage,
      path: strings.navigation.page.aboutPage,
      title: "About Us",
      icon: LayoutOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: strings.menu.valuesPage,
      path: strings.navigation.page.valuesPage,
      title: "Value Chains",
      icon: AppstoreOutlined,
      breadcrumb: false,
      submenu: []
    },{
      key: strings.menu.storiesPage,
      path: strings.navigation.page.storiesPage,
      title: "Stories",
      icon: CommentOutlined,
      breadcrumb: false,
      submenu: []
    },{
      key: strings.menu.resourcesPage,
      path: strings.navigation.page.resourcesPage,
      title: "Resources",
      icon: BookOutlined,
      breadcrumb: false,
      submenu: []
    },{
      key: strings.menu.eventsPage,
      path: strings.navigation.page.eventsPage,
      title: "Events",
      icon: ScheduleOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
  },
  {
    key: "Media (Gambar & Video)",
    path: strings.navigation.page.webimages,
    title: "Media (Gambar & Video)",
    icon: FileImageOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: "Value Chains",
    path: strings.navigation.page.value_chains,
    title: "Value Chains",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: []
  },{
    key: "Locations",
    path: strings.navigation.page.locations,
    title: "Locations",
    icon: CompassOutlined,
    breadcrumb: false,
    submenu: []
  },{
    key: "Messages",
    path: strings.navigation.page.messages,
    title: "Messages",
    icon: MessageOutlined,
    breadcrumb: false,
    submenu: []
  },{
    key: "Notification",
    path: strings.navigation.page.notification,
    title: "Latest Posts (Semi Auto)",
    icon: NotificationOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: "Website Alternatif",
    path: strings.navigation.page.wordpress,
    title: "Website Alternatif",
    icon: GlobalOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: "Laporan Error/Bug",
    path: strings.navigation.page.tickets,
    title: "Laporan Error/Bug",
    icon: ScheduleOutlined,
    breadcrumb: false,
    submenu: []
  }
]
}]

const menuNavTree = [{
  key: "Pengaturan Artikel",
  path: strings.navigation.page.events,
  title: "Pengaturan Artikel",
  icon: LayoutOutlined,
  breadcrumb: false,
  submenu: [
  {
    key: strings.menu.events,
    path: strings.navigation.page.events,
    title: strings.menu.events,
    icon: ScheduleOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: strings.menu.resources,
    path: strings.navigation.page.resources,
    title: strings.menu.resources,
    icon: BookOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: strings.menu.stories,
    path: strings.navigation.page.stories,
    title: strings.menu.stories,
    icon: CommentOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: strings.menu.images,
    path: strings.navigation.page.images,
    title: strings.menu.images,
    icon: FileImageOutlined,
    breadcrumb: false,
    submenu: []
  }
]
}]

const configNavTree = [{
  key: strings.menu.management,
  path: strings.navigation.page.users,
  title: strings.menu.management,
  icon: LayoutOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: strings.menu.users,
      path: strings.navigation.page.users,
      title: strings.menu.users,
      icon: UserSwitchOutlined,
      breadcrumb: false,
      submenu: []
    },
    // {
    //   key: "Use",
    //   path: "https://drive.google.com/drive/folders/17Gvr4gOHQ6vDdanxUd5UDoWxMHbMXRj4?usp=sharing",
    //   title: "How To Use",
    //   icon: AppstoreOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // }
  ]
}]

const navigationConfig = [
  ...dashBoardNavTree,
  ...pageNavTree,
  ...menuNavTree,
  ...configNavTree,
]

export default navigationConfig;
