import { Button, Card, Col, Form, Input, message, Row, Select, Textarea } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import Axios from 'axios';
import { PageHeaderAlt } from 'components/layout-components/PageHeaderAlt';
import Flex from 'components/shared-components/Flex';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useHistory, useLocation } from 'react-router-dom';
import { dimens, strings } from 'res';
import TextArea from "antd/lib/input/TextArea";

const AddResources = () => {

    const rules = {
        name: [
            {
                required: true,
                message: 'Please enter title',
            }
        ],
        website: [
            {
                required: true,
                message: 'Please enter website',
            }
        ],
        time: [
            {
                required: false,
            }
        ],
        date: [
            {
                required: false,
            }
        ],
        comparePrice: [
        ],
        taxRate: [
            {
                required: true,
                message: 'Please enter tax rate',
            }
        ],
        cost: [
            {
                required: true,
                message: 'Please enter item cost',
            }
        ]
    }

    const { Option, OptGroup } = Select;
    let history = useHistory();
    const [form] = Form.useForm();
    const location = useLocation();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [isLoadingCategory, setIsLoadingCategory] = useState(true);
    const [dataCategories, setDataCategories] = useState(strings.category.resources);
    const [uploadedImg, setImage] = useState('')
    const [uploadedPdf, setPdf] = useState('')
    const [editorState, setEditorState] = useState();
    const [editorStateEn, setEditorStateEn] = useState();
    const [defaultCategories, setDefaultCategories] = useState('')
    const [defaultImg, setDefaultImg] = useState('')
    const [defaultPdf, setDefaultPdf] = useState('')
    const [idItem, setIdItem] = useState();

    useEffect(() => {
        if (location.state.param === strings.option.update) {
            getDataDetail(location.state.id)
        } else {
            setDefaultImg([])
            setDefaultCategories([])
            setEditorState({ "entityMap": {}, "blocks": [{ "key": "637gr", "text": "", "type": "unstyled", "depth": 0, "inlineStyleRanges": [], "entityRanges": [], "data": {} }] })
            setEditorStateEn({ "entityMap": {}, "blocks": [{ "key": "637gr", "text": "", "type": "unstyled", "depth": 0, "inlineStyleRanges": [], "entityRanges": [], "data": {} }] })
        }
    }, [])

    const getDataDetail = (value) => {
        console.log(value.html)
        setIdItem(value._id);
        form.setFieldsValue({
            title: value.title,
            title_en: value.title_en,
            newsletter_link: value.link_url,
            author: value.author,
            date_string: value.date_string,
            desc:value.desc,
            google_url:value.google_url,
            desc_en:value.desc_en,
            category: value.category.split(',')
        })
        setDefaultCategories(value.category.split(','));
        setEditorState(JSON.parse(value.markdown))
        setEditorStateEn(JSON.parse(value.markdown_en))
        setDefaultImg([value.resources_thumbnail])
        setDefaultPdf(value.resources)
    }

    //Markdown
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    }

    const onEditorStateChangeEn = (editorState) => {
        setEditorStateEn(editorState);
    }

    const onUpdate = (values) => {
        setSubmitLoading(true)
        let formData = new FormData()
        let date = new Date();
        formData.append('title', values.title)
        formData.append('title_en', values.title_en)
        formData.append('link_url', values.newsletter_link)
        formData.append('google_url', values.google_url)
        formData.append('author', values.author)
        formData.append('category', values.category)
        formData.append('resources', uploadedPdf.file)
        formData.append('date_string', `${date.getDay()} ${strings.month[date.getMonth()]} ${date.getFullYear()}`)
        formData.append('resources_thumbnail', uploadedImg.file)
        formData.append('markdown', JSON.stringify(editorState))
        formData.append('markdown_en', JSON.stringify(editorStateEn))
        formData.append('desc', values.desc)
        formData.append('desc_en', values.desc_en)
        Axios.put(`${strings.url.content_url}/resource/update/${idItem}`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
                "Access-Control-Allow-Origin": "*"
            }
        }).then(doc => {
            if (doc.data.error === false) {
                setSubmitLoading(false)
                message.success(`${strings.response.success}`)
                history.goBack()
            }
        }).catch(e => {
            console.log('error: ', e.message)
        })
    }

    const onFinish = () => {
        form.validateFields().then(values => {
            if (values.category == null) message.error(strings.response.ischoose_categories);
            else {
                if (uploadedImg === '') message.error(strings.response.isuploaded_img);
                else {
                    confirm({
                        title: strings.default.confirmation.is_confirmation,
                        content: strings.default.confirmation.is_add,
                        onOk() {
                            if (location.state.param === strings.option.update) onUpdate(values);
                            else onPost(values);
                        },
                        onCancel() { },
                    });
                }
            }
        })
    }

    const onPost = (values) => {
        setSubmitLoading(true)
        let formData = new FormData()
        let date = new Date();
        formData.append('title', values.title)
        formData.append('title_en', values.title_en)
        formData.append('link_url', values.newsletter_link)
        formData.append('link_url_en', values.newsletter_link_en)
        formData.append('google_url', values.google_url)
        formData.append('author', values.author)
        formData.append('category', values.category)
        formData.append('resources', uploadedPdf.file)
        formData.append('date_string', `${date.getDay()} ${strings.month[date.getMonth()]} ${date.getFullYear()}`)
        formData.append('resources_thumbnail', uploadedImg.file)
        formData.append('markdown', JSON.stringify(editorState))
        formData.append('markdown_en', JSON.stringify(editorStateEn))
        formData.append('desc', values.desc)
        formData.append('desc_en', values.desc_en)
        Axios.post(`${strings.url.content_url}/resource/create`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
                "Access-Control-Allow-Origin": "*"
            }
        }).then(doc => {
            if (doc.data.error === false) {
                setSubmitLoading(false)
                message.success(`${strings.response.success}`)
                history.goBack()
            }
        }).catch(e => {
            console.log('error: ', e.message)
            message.error(e.message)
        })
    }

    const handleChange = (value) => { }

    const handleUploadChanged = (files) => {
        if (files.length > 0) {
            setImage({ id: files[0].name, file: files[0] })
        }
    }
    const handleUploadPdfChanged = (files) => {
        if (files.length > 0) {
            setPdf({ id: files[0].name, file: files[0] })
        }
    }

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            name="advanced_search"
            className="ant-advanced-search-form"
        >
            <PageHeaderAlt className="bg-white border-bottom" overlap>
                <div className="container">
                    <Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
                        <h2 className="mb-3">{strings.default.en.add_resources}</h2>
                        <div className="mb-3">
                            <Button className="mr-2" onClick={() => history.goBack()}> {strings.default.en.discard} </Button>
                            <Button type="primary" htmlType="submit" loading={submitLoading} >
                                {strings.default.en.save}
                            </Button>
                        </div>
                    </Flex>
                </div>
            </PageHeaderAlt>
            <div className="container" style={{ marginTop: dimens.medium }}>
                <Col>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12}>
                            <Card title={strings.default.indonesia}>
                                <Form.Item name={"title"} label={strings.default.id.title.label} rules={rules.name}>
                                    <Input placeholder={strings.default.id.title.label} />
                                </Form.Item>
                                <Form.Item name={"desc"} label={strings.default.id.desc.label} rules={rules.name}>
                                    <TextArea rows={5} placeholder="Deskripsi" />
                                </Form.Item>
                                <Form.Item name={"markdown"} label={strings.default.id.markdown.label}>
                                    {editorState && (
                                        <Card>
                                            <Editor
                                                initialContentState={editorState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onContentStateChange={onEditorStateChange}
                                                editorStyle={{ height: dimens.markdown_height }}
                                            />
                                        </Card>

                                    )}
                                </Form.Item>
                            </Card>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Card title={strings.default.english}>
                                <Form.Item name={"title_en"} label={strings.default.en.title_.label} rules={rules.name}>
                                    <Input placeholder={strings.default.en.title_.label} />
                                </Form.Item>
                                <Form.Item name={"desc_en"} label={strings.default.en.desc.label} rules={rules.name}>
                                    <TextArea rows={5} placeholder={strings.default.en.desc.label} />
                                </Form.Item>
                                <Form.Item name={"markdown_en"} label={strings.default.en.markdown.label}>
                                    {editorStateEn && (
                                        <Card>
                                            <Editor
                                                initialContentState={editorStateEn}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onContentStateChange={onEditorStateChangeEn}
                                                editorStyle={{ height: dimens.markdown_height }}
                                            />
                                        </Card>
                                    )}
                                </Form.Item>
                            </Card>
                        </Col>
                    </Row>
                    <Card title={strings.default.additional_info}>
                        <Form.Item name={"author"} label={strings.default.author.label} rules={rules.name}>
                            <Input placeholder={strings.default.author.label} />
                        </Form.Item>
                        <Form.Item name={"category"} label={strings.default.en.categories}>
                            {defaultCategories && (
                                <Select
                                    style={{ width: '100%' }}
                                    onChange={handleChange}
                                    mode="tags"
                                    defaultValue={defaultCategories}
                                    placeholder={strings.default.please_select}>
                                    {
                                        dataCategories.map(value => {
                                            return (<Option value={value.id} key={value.id}>{value.name}</Option>)
                                        })
                                    }
                                </Select>
                            )}
                        </Form.Item>
                        <Card title="Thumbnail">
                            <>
                                {!!defaultImg && (
                                    <DropzoneArea
                                        acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                                        onChange={handleUploadChanged}
                                        filesLimit={1}
                                        showAlerts={false}
                                        initialFiles={defaultImg}
                                    />
                                )}
                            </>
                        </Card>
                        <Card title="PDF (*Only if the file's size is below 5 mb)">
                            <>
                                {!!defaultImg && (
                                    <DropzoneArea
                                        acceptedFiles={['application/pdf']}
                                        onChange={handleUploadPdfChanged}
                                        filesLimit={1}
                                        showAlerts={false}
                                        initialFiles={defaultPdf}
                                    />
                                )}
                            </>
                                <label>{defaultPdf}</label>
                        </Card>
                        {/* <Form.Item name={"pdf"} label="PDF" >
                            <Input type="file" placeholder="PDF" />
                        </Form.Item> */}
                        <Form.Item name={"newsletter_link"} label="Newsletter's Link (*Only if the resource's type is Newsletter)" >
                            <Input placeholder="Link URL" />
                        </Form.Item>
                        <Form.Item name={"newsletter_link_en"} label="Newsletter's Link Eng Version (*Only if the resource's type is Newsletter)" >
                            <Input placeholder="Link URL English" />
                        </Form.Item>
                        <Form.Item name={"google_url"} label="Google's File Link" >
                            <Input placeholder="Link URL" />
                        </Form.Item>
                    </Card>
                </Col>
            </div>
        </Form>
    )
}

export default AddResources
