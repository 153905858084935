import { Button, Card, Col, Form, Input, message, Row, Select, Upload } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import Axios from 'axios';
import { PageHeaderAlt } from 'components/layout-components/PageHeaderAlt';
import Flex from 'components/shared-components/Flex';
import { DropzoneArea } from 'material-ui-dropzone';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useHistory, useLocation } from 'react-router-dom';
import { dimens, strings } from 'res';

const AddManagementImages = () => {

    const { Option, OptGroup } = Select;
    let history = useHistory();
    const [form] = Form.useForm();
    const location = useLocation();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [uploadedImg, setImage] = useState('')
    const [defaultImg, setDefaultImg] = useState('')
    const dateFormat = 'YYYY/MM/DD';
    const [idItem, setIdItem] = useState();

    useEffect(() => {
        if (location.state.param === strings.option.update) {
            getDataDetail(location.state.id)
        } else {
            setDefaultImg([])
        }
    }, []) 

    const getDataDetail = (value) => {
        console.log(value)
        setIdItem(value._id);
        form.setFieldsValue({
            title_idn: value.title,
            title_en: value.title_en,
            description_idn: value.desc,
            description_en: value.desc_en,
            author: value.author,
            Categories: value.category.split(',')
        })
        setDefaultImg([value.stories_thumbnail])
    }
    
    //Markdown

    const onUpdate = (values) => {
        setSubmitLoading(true)
        let formData = new FormData()
        let date = new Date();
        formData.append('title', values.title_idn)
        formData.append('title_en', values.title_en)
        formData.append('author', values.author)
        formData.append('category', values.Categories)
        formData.append('stories_thumbnail', uploadedImg.file)
        formData.append('date_string', `${date.getDay()} ${strings.month[date.getMonth()]} ${date.getFullYear()}`)
        formData.append('desc', values.description_idn)
        formData.append('desc_en', values.description_en)
        Axios.put(`${strings.url.content_url}/stories/update/${idItem}`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
                "Access-Control-Allow-Origin":"*"
            }
        }).then(doc => {
            if (doc.data.error === false) {
                setSubmitLoading(false)
                message.success(`${strings.response.success}`)
                history.goBack()
            }
        }).catch(e => {
            console.log('error: ', e.message)
        })
    }

    const onFinish = () => {
        form.validateFields().then(values => {
            confirm({
                title: strings.default.confirmation.is_confirmation,
                content: strings.default.confirmation.is_add,
                onOk() {
                    if (location.state.param === strings.option.update) onUpdate(values);
                    else onPost(values);
                },
                onCancel() {},
            });
        })
    }

    const onPost = (values) => {
        setSubmitLoading(true)
        let formData = new FormData()
        console.log(values);
        formData.append('name', values.name)
        formData.append('width', values.width)
        formData.append('height', values.height)
        formData.append('category', values.category)
        formData.append('images', uploadedImg.file)
        Axios.post(`${strings.url.content_url}/images/create`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
                "Access-Control-Allow-Origin":"*"
            }
        }).then(doc => {
            if (doc.data.error === false) {
                setSubmitLoading(false)
                message.success(`${strings.response.success}`)
                history.goBack()
            }
        }).catch(e => {
            console.log('error: ', e.message)
        })
    }

    const handleChange = (value) => {}

    const handleUploadChanged = (files) => {
        if (files.length > 0) {
            setImage({id: files[0].name, file: files[0]})
            //setDefaultImg([info.file])
        }
    }

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            name="advanced_search"
            className="ant-advanced-search-form"
        >
            <PageHeaderAlt className="bg-white border-bottom" overlap>
                <div className="container">
                    <Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
                        <h2 className="mb-3">{strings.default.en.add_resources}</h2>
                        <div className="mb-3">
                            <Button className="mr-2" onClick={() => history.goBack()}> {strings.default.en.discard} </Button>
                            <Button type="primary" htmlType="submit" loading={submitLoading} >
                                {strings.default.en.save}
                            </Button>
                        </div>
                    </Flex>
                </div>
            </PageHeaderAlt>
            <div className="container" style={{marginTop: dimens.medium}}>
                <Col>
                    <Card>
                        <Form.Item name={strings.default.en.name.name} label={strings.default.en.name.label}>
                            <Input placeholder={strings.default.en.name.label} />
                        </Form.Item>
                        <Form.Item name={strings.default.en.category.name} label={strings.default.en.category.label}>
                            <Input placeholder={strings.default.en.category.label} />
                        </Form.Item>
                        <Row gutter={16}>
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item name={strings.default.en.width.name} label={strings.default.en.width.label}>
                                    <Input placeholder={strings.default.en.width.label} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item name={strings.default.en.height.name} label={strings.default.en.height.label}>
                                    <Input placeholder={strings.default.en.height.label} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Card title={strings.default.media_image}>
                            <>
                                {!!defaultImg && (
                                    <DropzoneArea
                                        acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                                        onChange={handleUploadChanged}
                                        filesLimit={1}
                                        showAlerts={false}
                                        initialFiles={defaultImg}
                                    />
                                )}
                            </>
                        </Card>
                    </Card>
                </Col>
            </div>
        </Form>
    )
}

export default AddManagementImages
