import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import PrivateRoute from '../../components/PrivateRoute'
import { strings } from "res";
import {Button,Row,Col} from 'antd'
import Loading from 'components/shared-components/Loading';

// Pages
import Home from "./home";
import Events from "./events";
import Stories from "./stories";
import Resources from "./resources";
import Notification from "./notification";
import AddEvents from "./add-events";
import AddResources from "./add-resources";
import AddStories from "./add-stories";
import Messages from "./messages";
import DetailMessage from "./edit-messages";
import DetailNotification from "./edit-notification";

// Front App Pages
import PageAbout from "./page-about";
import PageEvents from "./page-events";
import PageHome from "./page-home";
import PageResources from "./page-resources";
import PageStories from "./page-stories";
import PageValues from "./page-values";
import AccountProfile from "./account-profile";
import Users from "./users";
import AddUsers from "./add-users";
import AddLocation from './add-location'
import WebDesign from "./web-desain"
import WebImages from "./web-images"
import EditResources from './edit-resources'

// Edit Contents
import AddImages from "./add-images";
import Images from "./images";
import Tickets from "./tickets";
import Subscribers from "./subscribers";
import SubscriberAnalytics from "./subscribers-analytics"
import AddManagementImages from "./add-management-images";
import AddTicket from "./add-ticket";
import AddValuesChains from "./add-value-chains";
import Location from './locations'
import ValuesChains from './value-chains'

const Wordpress = () =>{

  const openAdmin = ()=>{
    window.open("https://codedirect-project.com/web-gqsp/wordpress/wp-admin/","_blank")
  }

  const openWeb =()=>{
    window.open("https://codedirect-project.com/web-gqsp/wordpress/","_blank")
  }

  const staticWeb =()=>{
    window.open("https://gqspindonesia.org","_blank")
  }

  return(
    <div>
      <Row gutter={24}>
        <Col lg={12}>
          <Button onClick={staticWeb} style={{width:"100%"}} type="primary">Current Website</Button>
        </Col>
        <Col lg={12}>
          <Button onClick={openAdmin} style={{width:"100%"}} type="primary">Wordpress Admin</Button>
          <p>Username: admin Pass: TkTu4*T&IZzQ6T66FI</p>
          <br/>
          <br/>
          <Button onClick={openWeb} style={{width:"100%"}} type="primary">Wordpress Website</Button>
        </Col>
      </Row>
    </div>
  )
}

export const AppViews = ({match}) => {

  const [is_mounted, setMounted] = React.useState(false)

  React.useEffect(() => {
    setMounted(true)
  }, [])
  return is_mounted ? (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <PrivateRoute path={`${match.url}/${strings.navigation.path.home}`} component={Home} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.webimages}`} component={WebImages} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.events}`} component={Events} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.resources}`} component={Resources} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.locations}`} component={Location} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.stories}`} component={Stories} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.tickets}`} component={Tickets} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.value_chains}`} component={ValuesChains} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.add_value_chains}`} component={AddValuesChains} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.subscribers}`} component={Subscribers} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.subscribers_analytic}`} component={SubscriberAnalytics} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.add_events}`} component={AddEvents} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.add_resources}`} component={AddResources} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.edit_resource}`} component={EditResources} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.add_stories}`} component={AddStories} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.add_users}`} component={AddUsers} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.add_images}`} component={AddImages} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.add_ticket}`} component={AddTicket} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.add_location}`} component={AddLocation} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.add_management_images}`} component={AddManagementImages} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.aboutPage}`} component={PageAbout} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.homePage}`} component={PageHome} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.resourcesPage}`} component={PageResources} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.eventsPage}`} component={PageEvents} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.storiesPage}`} component={PageStories} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.valuesPage}`} component={PageValues} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.accountProfile}`} component={AccountProfile} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.users}`} component={Users} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.images}`} component={Images} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.desain}`} component={WebDesign} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.wordpress}`} component={Wordpress} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.messages}`} component={Messages} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.detail_message}`} component={DetailMessage} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.notification}`} component={Notification} />
        <PrivateRoute path={`${match.url}/${strings.navigation.path.detail_notification}`} component={DetailNotification} />
        {/* <Route path={`${match.url}/${strings.navigation.path.categoriesPage}`} component={PageCategories} /> */}
        <Redirect from={`${match.url}`} to={`${match.url}/${strings.navigation.path.home}`} />
        
      </Switch>
     </Suspense>
  ) : null
}

export default AppViews;
