import { InboxOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Form, Image, Input, message, Row, Select, TimePicker, Upload } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import Axios from 'axios';
import { PageHeaderAlt } from 'components/layout-components/PageHeaderAlt';
import Flex from 'components/shared-components/Flex';
import { DropzoneArea } from 'material-ui-dropzone';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ContentState, Editor, EditorState } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useHistory, useLocation } from 'react-router-dom';
import { dimens } from '../../../res/dimens';
import { strings } from '../../../res/strings';

const AddEvents = () => {

    const [form] = Form.useForm();
    const [submitLoading, setSubmitLoading] = useState(false)
    const [uploadedImg, setImage] = useState({})
    const [dataCategories, setDataCategories] = useState(strings.category.events)
    const [isLoading, setIsLoading] = useState(true);
    const [editorState, setEditorState] = useState();
    const [editorStateEn, setEditorStateEn] = useState(); 
    const [defaultImg, setDefaultImg] = useState('')
    const dateFormat = 'YYYY/MM/DD';
    const timeFormat = 'HH:mm:ss'
    const [idItem, setIdItem] = useState('')

    const { Option, OptGroup } = Select;
    let history = useHistory();
    const location = useLocation();
    // default form value
    const default_form_value = {
        date_event: moment(new Date(), dateFormat),
        time_after: moment(new Date(), timeFormat),
        time_before: moment(new Date(), timeFormat)
    }
    const [defaultCategories, setDefaultCategories] = useState('')

    const rules = {
        name: [
            {
                required: true,
                message: 'Please enter title',
            }
        ],
        website: [
            {
                required: true,
                message: 'Please enter website',
            }
        ],
        time: [
            {
                required: false,
            }
        ],
        date: [
            {
                required: false,
            }
        ],
    }

    useEffect(() => {
        if (location.state.param === strings.option.update) {
            getDataDetail(location.state.id)
        } else {
            setEditorState({"entityMap":{},"blocks":[{"key":"637gr","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]})
            setEditorStateEn({"entityMap":{},"blocks":[{"key":"637gr","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]})
            setDefaultImg([])
            setDefaultCategories('-')
        }

    }, [])

    //Markdown
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    }

    const onEditorStateChangeEn = (editorState) => {
        setEditorStateEn(editorState);
    }

    const getDataDetail = (value) => {
        console.log(value)
        setIdItem(value._id)
        form.setFieldsValue({
            title_idn: value.title,
            title_en: value.title_en,
            Website: value.title_en,
            description_idn: value.desc,
            description_en: value.desc_en,
            time_before: moment(value.start_hour, timeFormat),
            time_after: moment(value.finish_hour, timeFormat),
            date_event: moment(value.event_date)
        })
        setDefaultCategories(value.category)

        setEditorState(JSON.parse(value.markdown))
        setEditorStateEn(JSON.parse(value.markdown_en))
        setDefaultImg([value.events_thumbnail])
    }

    const onPost = (values) => {
        //isLoading
        setSubmitLoading(true);

        let formData = new FormData()
        let timeAfter = values.time_after == null ? moment(default_form_value.time_after).format(timeFormat) : moment(values.time_after).format(timeFormat)
        let timeBefore = values.time_before == null ? moment(default_form_value.time_before).format(timeFormat) : moment(values.time_before).format(timeFormat)
        let dateEvent = values.date_event == null ? moment(default_form_value.Date).format(dateFormat) : moment(values.date_event).format(dateFormat)

        formData.append('title', values.title_idn)
        formData.append('title_en', values.title_en)
        formData.append('category', values.Categories)
        formData.append('event_date', dateEvent)
        formData.append('start_hour', timeBefore)
        // formData.append('date_string', moment(new Date, dateFormat))
        formData.append('finish_hour', timeAfter)
        formData.append('markdown', JSON.stringify(editorState))
        formData.append('markdown_en', JSON.stringify(editorStateEn))
        formData.append('desc', values.description_idn)
        formData.append('desc_en', values.description_en)
        formData.append('events_thumbnail', uploadedImg.file)
        //Axios.post(`${strings.url.content_url}/events/create`, formData, {
        Axios.post(`${strings.url.proxy_url}${strings.url.content_url}/events/create`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
                "Access-Control-Allow-Origin":"*"
            }
        }).then(doc => {
            if (doc.data.error === false) {
                setSubmitLoading(false)
                message.success(`${strings.response.success}`)
                history.goBack()
            }
        }).catch(e => {
            console.log('error: ', e.message)
            message.error(e.message)
        })
    }

    const onUpdate = (values) => {
        //isLoading
        setSubmitLoading(true);

        let formData = new FormData()
        let timeAfter = values.time_after == null ? moment(default_form_value.time_after).format(timeFormat) : moment(values.time_after).format(timeFormat)
        let timeBefore = values.time_before == null ? moment(default_form_value.time_before).format(timeFormat) : moment(values.time_before).format(timeFormat)
        let dateEvent = values.date_event == null ? moment(default_form_value.Date).format(dateFormat) : moment(values.date_event).format(dateFormat)

        formData.append('title', values.title_idn)
        formData.append('title_en', values.title_en)
        formData.append('category', values.Categories)
        formData.append('event_date', dateEvent)
        formData.append('start_hour', timeBefore)
        formData.append('finish_hour', timeAfter)
        formData.append('markdown', JSON.stringify(editorState))
        formData.append('markdown_en', JSON.stringify(editorStateEn))
        formData.append('desc', values.description_idn)
        formData.append('desc_en', values.description_en)
        formData.append('events_thumbnail', uploadedImg.file)
        Axios.put(`${strings.url.content_url}/events/update/${idItem}`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
                "Access-Control-Allow-Origin":"*"
            }
        }).then(doc => {
            console.log(doc);
            if (doc.data.error === false) {
                setSubmitLoading(false)
                message.success(`${strings.response.success}`)
                history.goBack()
            }
        }).catch(e => {
            console.log('error: ', e.message)
        })
    }

    const onFinish = () => {
        form.validateFields().then(values => {
            console.log(values)
            if (values.Categories === '-') message.error(strings.response.ischoose_categories);
            else{
                if (!uploadedImg?.file) message.error(strings.response.isuploaded_img);
                else
                    confirm({
                        title: strings.default.confirmation.is_confirmation,
                        content: strings.default.confirmation.is_add,
                        onOk() {
                            if (location.state.param === strings.option.update) onUpdate(values);
                            else onPost(values);
                            
                        },
                        onCancel() {},
                    });
            }
            
        })
    }

    const handleUploadChanged = (files) => {
        if (files.length > 0) {
            setImage({id: files[0].name, file: files[0]})
        }
    }

    return (
        <Form
            layout="vertical"
            form={form}
            name="advanced_search"
            className="ant-advanced-search-form"
            onFinish={onFinish}
        >
            <PageHeaderAlt className="bg-white border-bottom" overlap>
                <div className="container">
                    <Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
                        <h2 className="mb-3">{strings.default.en.add_events}</h2>
                        <div className="mb-3">
                            <Button className="mr-2" htmlType="button" onClick={() => history.goBack()}> {strings.default.en.discard} </Button>
                            <Button type="primary" htmlType="submit" loading={submitLoading} >
                                {strings.default.en.save}
                            </Button>
                        </div>
                    </Flex>
                </div>
            </PageHeaderAlt>
            <div className="container" style={{marginTop: dimens.medium}}>
                <Col>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12}>
                            <Card title={strings.default.indonesia}>
                                <Form.Item name={strings.default.id.title.name} label={strings.default.id.title.label} rules={rules.name}>
                                    <Input placeholder='In Bahasa' />
                                </Form.Item>
                                <Form.Item name={strings.default.id.desc.name} label={strings.default.id.desc.label}>
                                    <Input placeholder='In Bahasa' />
                                </Form.Item>			
                                <Form.Item name={strings.default.id.markdown.name} label={strings.default.id.markdown.label}>
                                    {editorState && (
                                        <Editor
                                            initialContentState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onContentStateChange={onEditorStateChange}
                                            editorStyle={{height: dimens.markdown_height}}
                                        />
                                    )}
                                    
                                </Form.Item>			
                            </Card>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Card title={strings.default.english}>
                                <Form.Item name={strings.default.en.title_.name} label={strings.default.en.title_.label} rules={rules.name}>
                                    <Input placeholder='In English' />
                                </Form.Item>
                                <Form.Item name={strings.default.en.desc.name} label={strings.default.en.desc.label}>
                                    <Input placeholder='In English' />
                                </Form.Item>
                                <Form.Item name={strings.default.en.markdown.name} label={strings.default.en.markdown.label}>
                                    {
                                        editorStateEn && (
                                            <Editor
                                                initialContentState={editorStateEn}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onContentStateChange={onEditorStateChangeEn}
                                                editorStyle={{height: dimens.markdown_height}}
                                            />
                                    )}
                                </Form.Item>
                            </Card>
                        </Col>
                    </Row>
                    <Card title={strings.default.additional_info}>
                        <Form.Item name={strings.default.en.website} label={strings.default.en.website} rules={rules.website}>
                            <Input placeholder={strings.default.en.website} />
                        </Form.Item>
                        <Row gutter={16}>
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item name={strings.default.date_event.name} label={strings.default.date_event.label} rules={rules.date}>
                                    <DatePicker defaultValue={default_form_value.date_event} format={dateFormat} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                                <Row gutter={12}>
                                    <Col xs={24} sm={24} md={12}>
                                        <Form.Item name={strings.default.en.time_before.name} label={strings.default.en.time_before.label} rules={rules.time}>
                                            <TimePicker defaultValue={default_form_value.time_before} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12}>
                                        <Form.Item name={strings.default.en.time_after.name} label={strings.default.en.time_after.label} rules={rules.time}>
                                            <TimePicker defaultValue={default_form_value.time_after} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>	
                        <Form.Item name={strings.default.en.categories} label={strings.default.en.categories}>
                            {defaultCategories && (
                                <Select style={{ width: 200 }} defaultValue={defaultCategories.toString()}>
                                    {dataCategories.map((value, index) => {
                                        return (<Option value={value.id.toString()} key={index}>{value.name}</Option>)
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                        <>
                            {!!defaultImg && (
                                <DropzoneArea
                                    acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                                    onChange={handleUploadChanged}
                                    filesLimit={1}
                                    showAlerts={false}
                                    initialFiles={defaultImg}
                                />
                            )}
                        </>
                    </Card>
                </Col>
            </div>
        </Form>
    )
}

export default AddEvents
