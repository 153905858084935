const y = new Date().getFullYear();

export const memberIds = [
	'eileenHorton-1153',
	'terranceMoreno-1322',
	'ronVargas7653',
	'lukeCook4721',
	'joyceFreeman1384',
	'samanthaPhillips8493',
	'taraFletcher1263',
	'frederickAdams6532',
	'carolynHanson7953',
	'brittanyHale3683',
	'lloydObrien1564',
	'gabriellaMay2850',
	'leeWheeler1941',
	'gailBarnes7615',
	'ellaRobinson1093'
]

export const membersDetail = [
  {
    id: "eileenHorton-1153",
    name: "Eileen Horton",
    email: "eileen_h@hotmail.com",
    img: "/img/avatars/thumb-1.jpg"
  }
]

export const labels = [
	{
		color: 'blue',
		label: 'Task'
	},
	{
		color: 'gold',
		label: 'Bug'
	},
	{
		color: 'red',
		label: 'Live issue'
	},
	{
		color: 'cyan',
		label: 'Low priority'
	}
];
  
const homePageCard = [
	{
		id: 'zb7zxtjctd',
		name: 'Header-1 Components',
		description: 'Scenester hashtag sustainable.',
		cover: '/gqsp_img/bg-1-example.jpg',
		members: [],
		labels: ['Task'],
		attachments: [
			{
				'id': 'jubuK7XGp3',
				'name': 'background-1.jpg',
				'src': '/gqsp_img/bg-1-example.jpg',
				'size': '36.1kb'
			},
			{
				'id': 'xsb3HCejCM',
				'name': 'background-2.jpg',
				'src': '/gqsp_img/bg-2-example.jpg',
				'size': '55.9kb'
			},
			{
				'id': 'xsb3HCejCM',
				'name': 'background-3.jpg',
				'src': '/gqsp_img/bg-3-example.png',
				'size': '55.9kb'
			}
		],
		comments: [],
		'dueDate': 'Jumbotron'
	},
	{
		id: 'zb7zxtjctd',
		name: 'Our Approach',
		description: 'Scenester hashtag sustainable.',
		members: [],
		labels: ['Task'],
		attachments: [],
		comments: [],
		'dueDate': 'Column 3 x 3'
	},
	{
		id: 'zb7zxtjctd',
		name: 'Value Chains',
		description: 'Scenester hashtag sustainable.',
		members: [],
		labels: ['Task'],
		attachments: [],
		comments: [],
		'dueDate': 'Column 3 x 3'
	},
	{
		id: 'zb7zxtjctd',
		name: 'Resources',
		description: 'Scenester hashtag sustainable.',
		members: [],
		labels: ['Task'],
		attachments: [],
		comments: [],
		'dueDate': 'Column 3 x 3'
	},
	{
		id: 'zb7zxtjctd',
		name: 'Stories',
		description: 'Scenester hashtag sustainable.',
		members: [],
		labels: ['Task'],
		attachments: [],
		comments: [],
		'dueDate': 'Column 3 x 3'
	},
	{
		id: 'zb7zxtjctd',
		name: 'Social Media',
		description: 'Scenester hashtag sustainable.',
		members: [],
		labels: ['Task'],
		attachments: [],
		comments: [],
		'dueDate': 'Column 2 x 2'
	}
]

const aboutPageCard = [
	{
		id: 'zb7zxtjctd',
		name: 'About Us',
		cover: '/gqsp_img/about-video.png',
		description: 'Scenester hashtag sustainable.',
		members: [],
		labels: ['Task'],
		attachments: [],
		comments: [],
		'dueDate': 'Header'
	},{
		id: 'zb7zxtjctd',
		name: 'How We Works',
		cover: '/gqsp_img/about-bg.png',
		description: 'Scenester hashtag sustainable.',
		members: [],
		labels: ['Task'],
		attachments: [],
		comments: [],
		'dueDate': 'About Row'
	},{
		id: 'zb7zxtjctd',
		name: 'Our Approach',
		description: 'Scenester hashtag sustainable.',
		members: [],
		labels: ['Task'],
		attachments: [],
		comments: [],
		'dueDate': 'About Row'
	}
]

const valuePageCard = [
	{
		id: 'zb7zxtjctd',
		name: 'Value Chains',
		cover: '/gqsp_img/value_chains_jumbotron.png',
		description: 'Scenester hashtag sustainable.',
		members: [],
		labels: ['Task'],
		attachments: [],
		comments: [],
		'dueDate': 'Jumbotron'
	},{
		id: 'zb7zxtjctd',
		name: 'Grid 6 x 6',
		description: 'Scenester hashtag sustainable.',
		members: [],
		labels: ['Task'],
		attachments: [],
		comments: [],
		'dueDate': 'Grid 6 x 6'
	}
]

const storiesPageCard = [
	{
		id: 'zb7zxtjctd',
		name: 'Stories',
		description: 'Scenester hashtag sustainable.',
		members: [],
		labels: ['Task'],
		attachments: [],
		comments: [],
		'dueDate': 'List View'
	}
]

const resourcesPageCard = [
	{
		id: 'zb7zxtjctd',
		name: 'Resources',
		description: 'Scenester hashtag sustainable.',
		members: [],
		labels: ['Task'],
		attachments: [],
		comments: [],
		'dueDate': 'List View'
	}
]

const eventsPageCard = [
	{
		id: 'zb7zxtjctd',
		name: 'Events',
		description: 'Scenester hashtag sustainable.',
		members: [],
		labels: ['Task'],
		attachments: [],
		comments: [],
		'dueDate': 'List View'
	}
]

export const scrumboardData = {
	'Home Page': [...homePageCard],
	'About Page': [...aboutPageCard],
	'Value Chains Page': [...valuePageCard],
	'Stories Page': [...storiesPageCard],
	'Resources Page': [...resourcesPageCard],
	'Events Page': [...eventsPageCard]
}