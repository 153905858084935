import React, { Component } from "react";
import { Input, Button, Form, Modal, Card, Col, Row,message,Image } from "antd";
import {FileImageOutlined} from '@ant-design/icons';
import axios from 'axios'
import { strings } from 'res';

export class Tampilan extends Component {
    constructor(props){
        super(props)
        this.state = {
          homePageInd:{},
          homePageEng:{},
          visible: false,
          updateMessage:"",
          upload:{
            name: 'file',
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            headers: {
              authorization: 'authorization-text',
            },
            maxSize:1000000,
            onChange(info) {
              if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
              }
              if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            }
          }
        }
        this.handleChange = this.handleChange.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.showModal = this.showModal.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleOk = this.handleOk.bind(this);
      }
    
      showModal = () => {
        this.setState({
          visible: true,
        });
      };
    
      handleCancel = e => {
        console.log(e);
        this.setState({
          visible: false,
        });
      };
    
      handleOk = e => {
        console.log(e);
        this.setState({
          visible: false,
        });
      };
    
      componentDidMount(){
        axios.get(`${strings.url.content_url}/aboutPage/read`, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin":"*"
          }
        }).then(doc=>{
            this.setState({
                homePageInd:doc.data.aboutInd[0]
              })
        })
        axios.get(`${strings.url.content_url}/aboutPage/read`, {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin":"*"
            }
          }).then(doc=>{
              this.setState({
                  homePageEng:doc.data.aboutEng[0]
                })
          })
      }
    
      handleChange(event){
        this.setState({
          homePageInd:{
            ...this.state.homePageInd,
            [event.target.name]: event.target.value
           },
           homePageEng:{
            ...this.state.homePageEng,
            [event.target.name]: event.target.value
           },
        })
        console.log(this.state.homePageInd)
      }
    
       onFinish = (values) =>{
        axios.post(`${strings.url.content_url}/aboutPage/updateIndonesia`,this.state.homePageInd, {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin":"*"
            }
          }).then(doc=>{
            console.log(doc)
            this.setState({
              visible: true,
              updateMessage:doc.data.msg
            });
          })
          axios.post(`${strings.url.content_url}/aboutPage/updateEnglish`,this.state.homePageEng, {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin":"*"
            }
          }).then(doc=>{
            console.log(doc)
            this.setState({
              visible: true,
              updateMessage:doc.data.msg
            });
          })
      }

    render() {
        return (
            <div >
                 <Modal
                        title="Message!"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                    >
                        <h3 style={{textAlign:"center"}}>{this.state.updateMessage}</h3>
                    </Modal>
                <h1 style={{textAlign:"center"}}>About Page Structure</h1>
                <Card style={{ height: "100%" }}>
                    <Card>
                        <Row >
                            <h3 style={{textAlign:"center"}}>Navigation</h3>
                        </Row>
                    </Card>
                    <Card>
                        <Row gutter={24} type="flex">
                            <Col sm={24} md={24} lg={16}>
                                <Card type="flex" style={{textAlign:"center"}}>
                                    <FileImageOutlined style={{fontSize:"50px"}}></FileImageOutlined>
                                </Card>
                            </Col>
                            <Col sm={24} md={24} lg={8}>
                                <Card>
                                    <Row>
                                        About Us
                                    </Row>
                                    <br/>
                                    <Row>
                                        About Us Desc
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                    <Card>
                        <Row >
                            <h5 style={{textAlign:"center"}}>How We Work</h5>
                        </Row>
                        <Row gutter={24} type="flex">
                            <Col sm={24} md={24} lg={16}>
                                <Card>
                                    How We Work 1 + How We Work 1 Desc
                                </Card>
                                <Card>
                                    How We Work 2 + How We Work 2 Desc
                                </Card>
                                <Card>
                                    How We Work 3 + How We Work 3 Desc
                                </Card>
                            </Col>
                            <Col sm={24} md={24} lg={8}>
                                <Card>
                                    <p style={{textAlign:"center"}}>How We Work Desc</p>
                                </Card>
                                <Card>
                                    Video <br/> Section
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                    <Card>
                        <Row gutter={24} type="flex">
                            <Col sm={24} md={24} lg={8}>
                                <Card>
                                  Focus of Area - 1
                                </Card>
                            </Col>
                            <Col sm={24} md={24} lg={8}>
                                <Card>
                                  Focus of Area - 2
                                </Card>
                            </Col>
                            <Col sm={24} md={24} lg={8}>
                                <Card>
                                  Focus of Area - 3
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                    <Card>
                        <Row >
                            <h3 style={{textAlign:"center"}}>Where we Work</h3>
                        </Row>
                        <Row>
                            <p>Where we Work Desc</p>
                        </Row>
                        <Row gutter={24}>
                            <Col sm={24} md={24} lg={8}>
                                <Card type="flex" style={{textAlign:"center"}}>
                                    <FileImageOutlined style={{fontSize:"50px"}}></FileImageOutlined>
                                </Card>
                            </Col>
                            <Col sm={24} md={24} lg={8}>
                            <Card type="flex" style={{textAlign:"center"}}>
                                <FileImageOutlined style={{fontSize:"50px"}}></FileImageOutlined>
                            </Card>
                            </Col>
                            <Col sm={24} md={24} lg={8}>
                            <Card type="flex" style={{textAlign:"center"}}>
                                <FileImageOutlined style={{fontSize:"50px"}}></FileImageOutlined>
                            </Card>
                            </Col>
                        </Row>
                    </Card>
                    <Card>
                        <Row >
                            <h3 style={{textAlign:"center"}}>Stakeholders Section</h3>
                        </Row>
                    </Card>
                    <Card>
                        <Row >
                            <h3 style={{textAlign:"center"}}>Footer</h3>
                        </Row>
                    </Card>
                </Card>
                <h1>Images</h1>
                <Card style={{ height: "100%" }}>
                <Form onFinish={this.onFinish} >  
                    <label>Tentang Kami</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_1} name="image_1" placeholder="Image - 1" />
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_1}></Image>
                    <br />
                    <br />
                    <label>Bagaimana Kami Bekerja</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_2} name="image_2" placeholder="Image - 2" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_2}></Image>
                    <br />
                    <br />
                    <label>Bagaimana Kami Bekerja Background</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_3} name="image_3" placeholder="Image - 3" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_3}></Image>
                    <br />
                    <br />
                    <label>Apa yang Kami Kerjakan</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_4} name="image_4" placeholder="Image - 4" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_4}></Image>
                    {/* <br />
                    <br />
                    <label>Image - 5</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_5} name="image_5" placeholder="Image - 5" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_5}></Image>
                    <br />
                    <br />
                    <label>Image - 6</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_6} name="image_6" placeholder="Image - 6" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_6}></Image>
                    <br />
                    <br />
                    <label>Image - 7</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_7} name="image_7" placeholder="Image - 7" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_7}></Image>
                    <br />
                    <br />
                    <label>Image - 8</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_8} name="image_8" placeholder="Image - 8" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_8}></Image>
                    <br />
                    <br />
                    <label>Image - 9</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_9} name="image_9" placeholder="Image - 9" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_9}></Image>
                    <br />
                    <br />
                    <label>Image - 10</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_10} name="image_10" placeholder="Image - 10" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_10}></Image>
                    <br />
                    <br />
                    <label>Image - 11</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_11} name="image_11" placeholder="Image - 11" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_11}></Image>
                    <br />
                    <br />
                    <label>Image - 12</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_12} name="image_12" placeholder="Image - 12" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_12}></Image>
                    <br />
                    <br />
                    <label>Image - 13</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_13} name="image_13" placeholder="Image - 13" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_13}></Image>
                    <br />
                    <br />
                    <label>Image - 14</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_14} name="image_14" placeholder="Image - 14" />    
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_14}></Image>
                    <br />
                    <br /> */}
                     <br />
                    <br />
                    <Form.Item >
                        <Button htmlType="submit" type="primary" style={{width:"100%"}} >Submit</Button>
                    </Form.Item>
                </Form>
                </Card>
            </div>
        );
    }
}

export default Tampilan;
