import React, { Component } from "react";
import axios from 'axios'
import { Input,Button,Form,Modal } from "antd";
import { strings } from 'res';
import TextArea from "antd/lib/input/TextArea";
import { Editor } from 'react-draft-wysiwyg';

export class Basic extends Component {
  constructor(props){
    super(props)
    this.state = {
      homePageInd:{},
      visible:false,
      updateMessage:""
    }

    this.handleChange = this.handleChange.bind(this);
    this.onFinish = this.onFinish.bind(this);
    this.showModal = this.showModal.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  componentDidMount(){
    axios.get(`${strings.url.content_url}/aboutPage/read`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin":"*"
      }
    }).then(doc=>{
      if(this.props.bahasa === "Indonesia"){
        this.setState({
          homePageInd:doc.data.aboutInd[0]
        })
      }else{
        this.setState({
          homePageInd:doc.data.aboutEng[0]
        })
      }
     
    })
  }

  handleChange(event){
    this.setState({
      homePageInd:{
        ...this.state.homePageInd,
        [event.target.name]: event.target.value
       }
    })
  }

   onFinish = (values) =>{
    console.log(this.state)
    if(this.props.bahasa === "Indonesia"){
      axios.post(`${strings.url.content_url}/aboutPage/updateIndonesia`,this.state.homePageInd, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin":"*"
        }
      }).then(doc=>{
        this.setState({
          visible: true,
          updateMessage:doc.data.msg
        });
      })
    }else{
      axios.post(`${strings.url.content_url}/aboutPage/updateEnglish`,this.state.homePageInd, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin":"*"
        }
      }).then(doc=>{
        this.setState({
          visible: true,
          updateMessage:doc.data.msg
        });
      })
    }
  }

  render() {
    return (
      <div>
          <Modal
          title="Message!"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <h3 style={{textAlign:"center"}}>{this.state.updateMessage}</h3>
        </Modal>
        <h1>{this.props.bahasa}</h1>
        <Form onFinish={this.onFinish}>
        <label>About Us</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.about_us} name="about_us"/>
        <br />
        <br />
        <label>About Us Desc</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea  rows={10} onChange={this.handleChange} value={this.state.homePageInd.about_us_desc} name="about_us_desc" />    
        <br />
        <br />
        <label>How We Work</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.how_we_work} name="how_we_work" />
        <br />
        <br />
        <label>How We Work Desc</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.how_we_work_desc} name="how_we_work_desc" />
        <br />
        <br />
        <label>How We Work 1</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.how_we_work_1} name="how_we_work_1" />
        <br />
        <br />
        <label>How We Work 1 Desc</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.how_we_work_1_desc} name="how_we_work_1_desc" />
        <br />
        <br />
        <label>How We Work 2</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.how_we_work_2} name="how_we_work_2" />
        <br />
        <br />
        <label>How We Work 2 Desc</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.how_we_work_2_desc} name="how_we_work_2_desc" />
        <br />
        <br />
        <label>How We Work 3</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.how_we_work_3} name="how_we_work_3" />
        <br />
        <br />
        <label>How We Work 3 Desc</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.how_we_work_3_desc} name="how_we_work_3_desc" />
        <br />
        <br />
        <label>What We Do</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.what_we_do} name="what_we_do" />
        <br />
        <br />
        <label>What We Do Desc</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.what_we_do_desc} name="what_we_do_desc" />
        <br />
        <br />
        <label>What We Do 1</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.what_we_do_1} name="what_we_do_1" />
        <br />
        <br />
        <label>What We Do 1 Desc</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.what_we_do_1_desc} name="what_we_do_1_desc" />
        <br />
        <br />
        <label>What We Do 2</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.what_we_do_2} name="what_we_do_2" />
        <br />
        <br />
        <label>What We Do 2 Desc</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.what_we_do_2_desc} name="what_we_do_2_desc" />
        <br />
        <br />
        <label>What We Do 3</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.what_we_do_3} name="what_we_do_3" />
        <br />
        <br />
        <label>What We Do 3 Desc</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.what_we_do_3_desc} name="what_we_do_3_desc" />
        <br />
        <br />
        <label>Focus of Areas</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.focus_of_areas} name="focus_of_areas" />
        <br />
        <br />
        <label>Focus of Areas Desc</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.focus_of_areas_desc} name="focus_of_areas_desc" />
        <br />
        <br />
        <label>Focus of Area 1</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.focus_of_areas_1} name="focus_of_areas_1" />
        <br />
        <br />
        <label>Focus of Area 1 Description</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.focus_of_areas_1_desc} name="focus_of_areas_1_desc" />    
        <br />
        <br />
        <label>Focus of Area 2</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.focus_of_areas_2} name="focus_of_areas_2" />
        <br />
        <br />
        <label>Focus of Area 2 Description</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.focus_of_areas_2_desc} name="focus_of_areas_2_desc" />    
        <br />
        <br />
        <label>Focus of Area 3</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.focus_of_areas_3} name="focus_of_areas_3" />
        <br />
        <br />
        <label>Focus of Area 3 Description</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.focus_of_areas_3_desc} name="focus_of_areas_3_desc" />    
        <br />
        <br />
        <label>Where We Work</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.where_we_work} name="where_we_work" />
        <br />
        <br />
        <label>Where We Work Desc</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.where_we_work_desc} name="where_we_work_desc" />
        <br />
        <br />
        <label>Other Countries</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.other_countries} name="other_countries" />
        <br />
        <br />
        <label>Other Countries Desc</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.other_countries_desc} name="other_countries_desc" />
        <br />
        <br />
        <label>Button Text</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.button} name="button" placeholder="Button" />
        <br />
        <br />
        <Form.Item >
          <Button htmlType="submit" type="primary" style={{width:"100%"}} >Submit</Button>
        </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Basic;
