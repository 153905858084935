import React from 'react'
import { Input, Row, Col, Card, Form } from 'antd';

const rules = {
	name: [
		{
			required: true,
			message: 'Please enter image name',
		}
	],
	width: [		
		{
			required: true,
			message: 'Please enter width',
		}
	],
	height: [		
		{
			required: true,
			message: 'Please enter height',
		}
	]
}

const GeneralField = props => (
		<Row gutter={16}>
		<Col xs={24} sm={24} md={17}>
			<Card title="Basic Info">
				<Form.Item name="name" label="Image Title" rules={rules.name}>
					<Input placeholder="Image Name" />
				</Form.Item>
			</Card>
			<Card title="Dimension">
				<Row gutter={16}>
					<Col xs={24} sm={24} md={12}>
						<Form.Item name="width" label="Width" rules={rules.width}>
							<Input placeholder="Width" />
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={12}>
						<Form.Item name="height" label="Height" rules={rules.height}>
							<Input placeholder="Height" />
						</Form.Item>
					</Col>
				</Row>
			</Card>
		</Col>
		<Col xs={24} sm={24} md={7}>
			<Card title="Media">
				<Input required name="resources" accept=".png,.jpeg,.jpg" type="file" placeholder="PDF"></Input>
				<img alt="" style={{width:"100%",padding:"5%"}} src="https://gqspindonesia.org/Shrimp@2x.png" ></img>
			</Card>
		</Col>
	</Row>
)

export default GeneralField
