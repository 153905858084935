import React, { useEffect, useState } from "react";
import { Row, Col, Button, Card, Dropdown, Table, Menu } from 'antd';
import StatisticWidget from 'components/shared-components/StatisticWidget';
import ChartWidget from 'components/shared-components/ChartWidget';
import GoalWidget from 'components/shared-components/GoalWidget';
import {
  VisitorChartData,
  ActiveMembersData,
} from './DefaultDashboardData';
import ApexChart from "react-apexcharts";
import { apexLineChartDefaultOption, COLOR_2 } from 'constants/ChartConstant';
import {
  FileExcelOutlined,
  PrinterOutlined,
  EllipsisOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { strings } from 'res/strings'
import utils from 'utils';
import exampleService from 'services/ExampleService'
import { withRouter } from 'react-router-dom';
import axios from 'axios'
import moment from 'moment-timezone'

const MembersChart = props => (
  <ApexChart {...props} />
)

const memberChartOption = {
  ...apexLineChartDefaultOption,
  ...{
    chart: {
      sparkline: {
        enabled: true,
      }
    },
    colors: [COLOR_2],
  }
}

const pushRoute = () => {
  console.log('execute')
  exampleService.getPost().then(resp => {
    console.log('resp', resp)
  })
}

const latestTransactionOption = (
  <Menu>
    <Menu.Item key="0">
      <span>
        <div className="d-flex align-items-center">
          <ReloadOutlined />
          <span className="ml-2">Refresh</span>
        </div>
      </span>
    </Menu.Item>
    <Menu.Item key="1">
      <span>
        <div className="d-flex align-items-center">
          <PrinterOutlined />
          <span className="ml-2">Print</span>
        </div>
      </span>
    </Menu.Item>
    <Menu.Item key="12">
      <span>
        <div className="d-flex align-items-center">
          <FileExcelOutlined />
          <span className="ml-2">Export</span>
        </div>
      </span>
    </Menu.Item>
  </Menu>
);

const cardDropdown = (menu) => (
  <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
    <a href="/#" className="text-gray font-size-lg" onClick={e => e.preventDefault()}>
      <EllipsisOutlined />
    </a>
  </Dropdown>
)

const tableColumns = [
  {
    title: 'Id',
    dataIndex: '_id',
    sorter: (a, b) => utils.antdTableSorter(a, b, '_id')
  },
  {
    title: 'First Name',
    dataIndex: 'first_name',
    sorter: (a, b) => utils.antdTableSorter(a, b, 'first_name')
  },
  {
    title: 'Last Name',
    dataIndex: 'last_name',
    sorter: (a, b) => utils.antdTableSorter(a, b, 'last_name')
  },
  {
    title: 'Email',
    dataIndex: 'email',
    sorter: (a, b) => utils.antdTableSorter(a, b, 'email')
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    sorter: (a, b) => utils.antdTableSorter(a, b, 'gender')
  },
  {
    title: 'City',
    dataIndex: 'city',
    sorter: (a, b) => utils.antdTableSorter(a, b, 'city')
  },
  {
    title: 'Occupation',
    dataIndex: 'occupation',
    sorter: (a, b) => utils.antdTableSorter(a, b, 'occupation')
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    sorter: (a, b) => utils.antdTableSorter(a, b, 'phone')
  }
];

export const DefaultDashboard = () => {
  // const [visitorChartData] = useState(VisitorChartData);
  const [activeMembersData] = useState(ActiveMembersData);
  const [subscribers, setSubscribers] = useState([]);
  const [data, setData] = useState({});
  const [visitor, setVisitor] = useState([moment().tz('Asia/Jakarta').subtract(7, 'days').format('ll'),moment().tz('Asia/Jakarta').subtract(6, 'days').format('ll'),moment().tz('Asia/Jakarta').subtract(5, 'days').format('ll'),moment().tz('Asia/Jakarta').subtract(4, 'days').format('ll'),moment().tz('Asia/Jakarta').subtract(3, 'days').format('ll'),moment().tz('Asia/Jakarta').subtract(2, 'days').format('ll'),moment().tz('Asia/Jakarta').subtract(1, 'days').format('ll'),moment().tz('Asia/Jakarta').format('ll')]);

  const [storage, setStorage] = useState({
    _available: 20905044,
    _blocks: 25226960,
    _capacity: "18%",
    _filesystem: "/dev/vda1",
    _mounted: "/",
    _used: 4305532
  });

  const [annualStatisticData, setAnnualStatisticData] = useState([
    {
      title: 'Images',
      value: '0'
    },
    {
      title: 'Events',
      value: '0',
    },
    {
      title: 'Stories',
      value: '0'
    },
    {
      title: 'Recources',
      value: '0'
    }
  ])

  const getData = async () => {
    let counts = await axios.get(`${strings.url.content_url}/dashboard/getAllCounts`, {
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*"
      }
    })
    let dashboardData = counts.data
    setAnnualStatisticData([
      {
        title: 'Images',
        value: dashboardData.imagesData
      },
      {
        title: 'Events',
        value: dashboardData.eventsData,
      },
      {
        title: 'Stories',
        value: dashboardData.storiesData
      },
      {
        title: 'Recources',
        value: dashboardData.resourcesData
      }
    ])
    setData(dashboardData)
  }

  // /visitor/get-7-days-data
  const getVisitor = () => {
    axios.get(`${strings.url.content_url}/visitor/get-7-days-data`, {
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*"
      }
    }).then(doc=>{
      let visitors = doc.data
      console.log(visitors.dates)
      setVisitor(visitors.dates)
    })
    
  }

  const getSubscribers = async () => {
    let counts = await axios.get(`${strings.url.content_url}/subscriber/read`, {
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*"
      }
    })
    let recent_subscribers = counts.data.doc
    setSubscribers(recent_subscribers)
  }

  const getStorageInfo = async () => {
    let info = await axios.get(`${strings.url.content_url}/server-info`, {
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*"
      }
    })
    let storageData = info.data[2]
    setStorage({
      ...storage,
      storageData
    })
  }

  useEffect(() => {
    getVisitor()
    getData()
    getSubscribers()
    getStorageInfo()
  }, [])

  return (
    <>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={18}>
          <Row gutter={16}>
            {
              annualStatisticData.map((elm, i) => (
                <Col xs={24} sm={24} md={24} lg={24} xl={6} key={i}>
                  <StatisticWidget
                    title={elm.title}
                    value={elm.value}
                    status={elm.status}
                    subtitle={elm.subtitle}
                  />
                </Col>
              ))
            }
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <ChartWidget
                title={"Visitors Today: "+`${moment().tz('Asia/Jakarta').format('ll')}`}
                series={[
                  {
                    name: "Page Views",
                    data: [35, 41, 62, 42, 13, 18, 29]
                  }
                ]}
                xAxis={visitor}
                height={400}
              />
              {/* <Button type="primary" onClick={()=>console.log(visitor)}>Learn More</Button> */}
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6}>
          <GoalWidget
            title="Available Storage"
            value={((storage._available / storage._blocks) * 100).toFixed(2)}
            subtitle={"Total Storage: " + (storage._blocks / 1000000).toFixed(2) + "Gb"}
            extra={<Button type="primary" onClick={() => pushRoute()}>Learn More</Button>}
          />
          <StatisticWidget
            title={
              <MembersChart
                options={memberChartOption}
                series={activeMembersData}
                height={145}
              />
            }
            value={data.usersData}
            status={3.7}
            subtitle="Active Users"
          />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card title="Subscribers" extra={cardDropdown(latestTransactionOption)}>
            <Table
              className="no-border-last"
              columns={tableColumns}
              dataSource={subscribers}
              rowKey='id'
              pagination={false}
            />
          </Card>
        </Col>
      </Row>
    </>
  )
}


export default withRouter(DefaultDashboard);
