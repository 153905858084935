import { DeleteOutlined, EyeOutlined, PlusCircleOutlined, SearchOutlined,UserOutlined } from '@ant-design/icons';
import { red } from '@material-ui/core/colors';
import { Button, Card, Input, Menu, message, Select, Table } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import Axios from 'axios';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import Flex from 'components/shared-components/Flex';
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { strings } from 'res';
import utils from 'utils';


const Users = () => {
	let history = useHistory();
	const [selectedRows, setSelectedRows] = useState([])
	const [selectedRowKeys, setSelectedRowKeys] = useState([])

	const dropdownMenu = row => (
		<Menu>
			{/* <Menu.Item onClick={() => viewDetails(row)}>
				<Flex alignItems="center">
					<EyeOutlined />
					<span className="ml-2">View Details</span>
				</Flex>
			</Menu.Item> */}
			<Menu.Item onClick={() => updateRoleById(row)}>
				<Flex alignItems="center">
					<UserOutlined />
					<span className="ml-2">Set As Admin</span>
				</Flex>
			</Menu.Item>
			<Menu.Item onClick={() => deleteRow(row)}>
				<Flex alignItems="center">
					<DeleteOutlined />
					<span className="ml-2">{selectedRows.length > 0 ? `Delete (${selectedRows.length})` : 'Delete'}</span>
				</Flex>
			</Menu.Item>
		</Menu>
	);
	
	const addStories = () => {
		history.push(strings.navigation.page.addUsers)
	}
	
	const viewDetails = row => {
		history.push(`/app/apps/ecommerce/edit-product/${row.id}`)
	}
	
	const deleteRow = row => {
		confirm({
			title: strings.default.confirmation.is_confirmation,
			content: strings.default.confirmation.is_delete,
			onOk() {
				onDelete(row);
			},
			onCancel() {},
		});
	}

	const updateRoleById = (row) => {
		setIsLoading(true)
		console.log(row)
		Axios.get(`${strings.url.content_url}/users/updateRoleById/${row._id}`, { headers: {
			'Content-Type': 'application/json',
			"Access-Control-Allow-Origin":"*"
		  }}).then(doc => {
			if(doc.data !== null){
				getData()
				setIsLoading(false)
			}
		}).catch(e => {
			message.error(e.message)
			setIsLoading(false)
		})
	}

	const onDelete = (row) => {
		setIsLoading(true)
		Axios.get(`${strings.url.content_url}/users/delete/${row._id}`, { headers: {
			'Content-Type': 'application/json',
			"Access-Control-Allow-Origin":"*"
		  }}).then(doc => {
			if(doc.data !== null){
				getData()
				setIsLoading(false)
			}
		}).catch(e => {
			message.error(e.message)
			setIsLoading(false)
		})
	}

	const tableColumns = [
		{
			title: `${strings.default.en.id}`,
			dataIndex: '_id',
			sorter: (a, b) => utils.antdTableSorter(a, b, '_id')
		},
		{
			title: `${strings.default.name_users}`,
			dataIndex: 'username',
			sorter: (a, b) => utils.antdTableSorter(a, b, 'username')
		},
		{
			title: `${strings.default.email_users}`,
            dataIndex: 'email',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'email')
		},
		{
			title: `Role`,
			dataIndex: 'role',
			render: (_, record) => {
				if(record.role === 0){
					return (
						<div className="d-flex" style={{color:"green"}}>
							Admin
						</div>
					)
				}else{
					return (
						<div className="d-flex" style={{color:"red"}}>
							Not Admin
						</div>
					)
				}
			},
			sorter: (a, b) => utils.antdTableSorter(a, b, 'role')
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right">
					<EllipsisDropdown menu={dropdownMenu(elm)}/>
				</div>
			)
		}
	];

	const onSearch = e => {
		const value = e.currentTarget.value
		const searchArray = e.currentTarget.value? dataItem : dataItemBackup
		const data = utils.wildCardSearch(searchArray, value)
		setDataItem(data)
		setSelectedRowKeys([])
	}

	useEffect(() => {
		getData()
	}, [])

	const [dataItem, setDataItem] = useState([]);
	const [dataItemBackup, setDataItemBackup] = useState([]);
	const [isLoading, setIsLoading] = useState(true)

	const getData = async() => {
		let res = await Axios.get(`${strings.url.content_url}/users/read`, {
			headers: {
			  'Content-Type': 'application/json',
			  "Access-Control-Allow-Origin":"*"
			}
		})
		console.log(res.data.doc)
		setDataItemBackup(res.data.doc)
		setDataItem(res.data.doc);
		setIsLoading(false);
	}

	return (
		<Card>
			<Flex alignItems="center" justifyContent="between" mobileFlex={false}>
				<Flex className="mb-1" mobileFlex={false}>
					<div className="mr-md-3 mb-3">
						<Input placeholder="Search" prefix={<SearchOutlined />} onChange={e => onSearch(e)}/>
					</div>
				</Flex>
				{/* <div>
					<Button onClick={() => addStories()} type="primary" icon={<PlusCircleOutlined />} block>{strings.default.add_users}</Button>
				</div> */}
			</Flex>
			<div className="table-responsive">
				<Table
					loading={isLoading} 
					columns={tableColumns} 
					dataSource={dataItem} 
					rowKey='id_users' 
				/>
			</div>
		</Card>
	)
}

export default Users
