import { DeleteOutlined, EyeOutlined, PlusCircleOutlined, SearchOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Input, Menu, Select, Table } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import Axios from 'axios';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import Flex from 'components/shared-components/Flex';
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import utils from 'utils';
import { strings } from '../../../res/strings';

const { Option } = Select

const Subscribers = () => {
	let history = useHistory();
	const [selectedRows, setSelectedRows] = useState([])
	const [selectedRowKeys, setSelectedRowKeys] = useState([])

	const dropdownMenu = row => (
		<Menu>
			<Menu.Item onClick={() => onUpdate(row)}>
				<Flex alignItems="center">
					<EditOutlined />
					<span className="ml-2">{strings.option.update}</span>
				</Flex>
			</Menu.Item>
			<Menu.Item onClick={() => viewDetails(row)}>
				<Flex alignItems="center">
					<EyeOutlined />
					<span className="ml-2">{strings.option.detail}</span>
				</Flex>
			</Menu.Item>
			<Menu.Item onClick={() => deleteRow(row)}>
				<Flex alignItems="center">
					<DeleteOutlined />
					<span className="ml-2">{selectedRows.length > 0 ? `Delete (${selectedRows.length})` : `${strings.option.remove}`}</span>
				</Flex>
			</Menu.Item>
		</Menu>
	);
	
	const onAdding = () => {
		history.push({
			pathname: strings.navigation.page.addEvents,
			state: {
				param: strings.option.add
			}
		})
	}
	
	const viewDetails = row => {
		history.push(`/app/apps/ecommerce/edit-product/${row.id}`)
	}

	const onUpdate = (row) => {
		history.push({
			pathname: strings.navigation.page.addEvents,
			state: {
				param: strings.option.update,
				id: row
			}
		})
	}
	
	const deleteRow = row => {
		confirm({
			title: strings.default.confirmation.is_confirmation,
			content: strings.default.confirmation.is_delete,
			onOk() {
				onDelete(row);
			},
			onCancel() {},
		});
	}

	const onDelete = (row) => {
		setIsLoading(true)
		Axios.get(`${strings.url.content_url}/subscriber/delete/${row._id}`, { headers: {
			'Content-Type': 'application/json',
            "Access-Control-Allow-Origin":"*"
			}}).then(doc => {
			if(doc.data !== null){
				getData()
			}
			setIsLoading(false)
		}).catch(e => {
			console.log(e.message)
		})
	}

	const [dataItem, setDataItem] = useState([]);
	const [dataItemBackup, setDataItemBackup] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [dataCategories, setDataCategories] = useState(strings.category.events)

	useEffect(() => {
		getData();
	}, [])

	const getData = async () => {
		let res = await Axios.get(`${strings.url.content_url}/subscriber/read`, {
			headers: {
			  'Content-Type': 'application/json',
              "Access-Control-Allow-Origin":"*"
			}
        })
		setDataItemBackup(res.data.doc)
		setDataItem(res.data.doc);
		setIsLoading(false);
	}

	const tableColumns = [
        {
			title: 'Id',
			dataIndex: '_id',
			sorter: (a, b) => utils.antdTableSorter(a, b, '_id')
		},
		{
			title: 'First Name',
			dataIndex: 'first_name',
			sorter: (a, b) => utils.antdTableSorter(a, b, 'first_name')
		},
		{
			title: 'Last Name',
			dataIndex: 'last_name',
			sorter: (a, b) => utils.antdTableSorter(a, b, 'last_name')
		},
		{
			title: 'Email',
			dataIndex: 'email',
			sorter: (a, b) => utils.antdTableSorter(a, b, 'email')
		},
		{
			title: 'Gender',
			dataIndex: 'gender',
			sorter: (a, b) => utils.antdTableSorter(a, b, 'gender')
		},
		{
			title: 'City',
			dataIndex: 'city',
			sorter: (a, b) => utils.antdTableSorter(a, b, 'city')
		},
		{
			title: 'Occupation',
			dataIndex: 'occupation',
			sorter: (a, b) => utils.antdTableSorter(a, b, 'occupation')
		},
		{
			title: 'Phone',
			dataIndex: 'phone',
			sorter: (a, b) => utils.antdTableSorter(a, b, 'phone')
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right">
					<EllipsisDropdown menu={dropdownMenu(elm)}/>
				</div>
			)
		}
	];
	
	const rowSelection = {
		onChange: (key, rows) => {
			setSelectedRows(rows)
			setSelectedRowKeys(key)
		}
	};

	const onSearch = e => {
		const value = e.currentTarget.value
		const searchArray = e.currentTarget.value? dataItem : dataItemBackup
		const data = utils.wildCardSearch(searchArray, value)
		setDataItem(data)
		setSelectedRowKeys([])
	}

	const handleShowCategory = value => {
		if(value !== 'All') {
			let res = dataItem.filter(item => item.category === value)
			setDataItemBackup(dataItem)
			setDataItem(res)
		} else {
			setDataItem(dataItemBackup)
		}
	}

	return (
		<Card>
			<Flex alignItems="center" justifyContent="between" mobileFlex={false}>
				<Flex className="mb-1" mobileFlex={false}>
					<div className="mr-md-3 mb-3">
						<Input placeholder="Search" prefix={<SearchOutlined />} onChange={e => onSearch(e)}/>
					</div>
					<div className="mb-3">
						<Select 
							defaultValue="All" 
							className="w-100" 
							style={{ minWidth: 180 }} 
							onChange={handleShowCategory} 
							placeholder="Category"
						>
							<Option value="All">All</Option>
							{
								dataCategories.map(value => {
									return  (<Option value={value.id.toString()} key={value.id.toString()}>{value.name}</Option>)
								})
							}
						</Select>
					</div>
				</Flex>
				<div>
					<Button onClick={onAdding} type="primary" icon={<PlusCircleOutlined />} block>Add Subscriber</Button>
				</div>
			</Flex>
			<div className="table-responsive">
				<Table 
					columns={tableColumns} 
					dataSource={dataItem} 
					rowKey='_id' 
					loading={isLoading}
				/>
			</div>
		</Card>
	)
}


export default Subscribers
