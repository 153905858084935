import { DeleteOutlined, EyeOutlined, PlusCircleOutlined, SearchOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Input, Menu, Select, Table } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import Axios from 'axios';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import Flex from 'components/shared-components/Flex';
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { strings } from 'res';
import utils from 'utils';

const Tickets = () => {
	let history = useHistory();
	const [selectedRows, setSelectedRows] = useState([])
	const [selectedRowKeys, setSelectedRowKeys] = useState([])

	const dropdownMenu = row => (
		<Menu>
			<Menu.Item onClick={() => onUpdate(row)}>
				<Flex alignItems="center">
					<EyeOutlined />
					<span className="ml-2"> View Detail </span>
				</Flex>
			</Menu.Item>
			<Menu.Item onClick={() => deleteRow(row)}>
				<Flex alignItems="center">
					<DeleteOutlined />
					<span className="ml-2">{selectedRows.length > 0 ? `Delete (${selectedRows.length})` : strings.option.remove}</span>
				</Flex>
			</Menu.Item>
		</Menu>
	);
	
	const onUpdate = row => {
		history.push({
			pathname: strings.navigation.page.detail_notification,
			state: {
				param: strings.option.update,
				id: row
			}
		})
	}

	const addNotification = () => {
		history.push({
			pathname: strings.navigation.page.detail_notification,
			state: {
				param: strings.option.add
			}
		})
	}
	
	const deleteRow = row => {
		confirm({
			title: strings.default.confirmation.is_confirmation,
			content: strings.default.confirmation.is_delete,
			onOk() {
				onDelete(row);
			},
			onCancel() {},
		});
	}

	const onDelete = (row) => {
		setIsLoading(true)
		Axios.get(`${strings.url.content_url}/notification/delete/${row._id}`, 
		{ 
			headers: {
			'Content-Type': 'application/json',
			"Access-Control-Allow-Origin":"*"
		  }}).then(doc => {
			if(doc.data !== null){
				getData()
				setIsLoading(false)
			}
		}).catch(e => {
			console.log(e.message)
		})
	}

	const tableColumns = [
		{
			title: "Id",
			dataIndex: '_id',
			sorter: (a, b) => utils.antdTableSorter(a, b, '_id')
		},
		{
			title: "Title",
			dataIndex: 'title',
			sorter: (a, b) => utils.antdTableSorter(a, b, 'title')
		},
		{
			title: "Description",
			dataIndex: 'desc',
			sorter: (a, b) => utils.antdTableSorter(a, b, 'desc')
		},
		{
			title: "Date",
			dataIndex: 'date_string',
			sorter: (a, b) => utils.antdTableSorter(a, b, 'date_string')
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right">
					<EllipsisDropdown menu={dropdownMenu(elm)}/>
				</div>
			)
		}
	];

	const onSearch = e => {
		const value = e.currentTarget.value
		const searchArray = e.currentTarget.value? dataItem : dataItemBackup
		const data = utils.wildCardSearch(searchArray, value)
		setDataItem(data)
		setSelectedRowKeys([])
	}

	useEffect(() => {
		getData()
	}, [])

	const [dataItem, setDataItem] = useState([]);
	const [dataItemBackup, setDataItemBackup] = useState([]);
	const [dataCategories, setDataCategories] = useState(strings.category.stories)
	const [isLoading, setIsLoading] = useState(true)

	const getData = async() => {
		let res = await Axios.get(`${strings.url.content_url}/notification/read`, {
			headers: {
			  'Content-Type': 'application/json',
			  "Access-Control-Allow-Origin":"*"
			}
		})
		console.log(res)
		setDataItemBackup(res.data.docs)
		setDataItem(res.data.docs);
		setIsLoading(false);
	}

	return (
		<Card>
			<Flex alignItems="center" justifyContent="between" mobileFlex={false}>
				<Flex className="mb-1" mobileFlex={false}>
					<div className="mr-md-3 mb-3">
						<Input placeholder="Search" prefix={<SearchOutlined />} onChange={e => onSearch(e)}/>
					</div>
				</Flex>
				<div>
					<Button onClick={() => addNotification()} type="primary" icon={<PlusCircleOutlined />} block>Add Notification</Button>
				</div>
			</Flex>
			<div className="table-responsive">
				<Table
					loading={isLoading} 
					columns={tableColumns} 
					dataSource={dataItem} 
					rowKey='_id' 
				/>
			</div>
		</Card>
	)
}

export default Tickets
