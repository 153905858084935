import React, { useState, useEffect } from 'react'
import PageHeaderAlt from 'components/layout-components/PageHeaderAlt'
import { Tabs, Form, Button, message, Input, Row, Col, Card } from 'antd';
import Flex from 'components/shared-components/Flex'
import axios from 'axios'
import { strings } from 'res';
import { useHistory } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';

const { TabPane } = Tabs;

const ADD = 'ADD'
const EDIT = 'EDIT'

const ProductForm = props => {

	const { mode = ADD, param } = props

	const [form] = Form.useForm();

	const [dataForm, setDataForm] = useState({
		name:"",
		path:"",
		width:"",
		height:"",
		category:"general",
		value_chains:"general",
		desc:"",
		video_link:""
	})
	const [images, setImages] = useState()

	const history = useHistory()

	const pickImageHandler = (event) => {
		if(event.target.files[0].size < 1000000){
			setImages(event.target.files[0])
		}else{
			message.error("Opps, it looks like the maximum image size is 1 Mb")
		}
    }

	useEffect(() => {
		if (mode === EDIT) {
			console.log('is edit')
			console.log('props', props)
		}
	}, [form, mode, param, props]);

	const onSubmitHandle = (event) => {
		console.log(event.target.value)
		if (mode === ADD) {
			let formData = new FormData() 
			formData.append('name', dataForm.name)
			formData.append('width', dataForm.width)
			formData.append('height', dataForm.height)
			formData.append('category', dataForm.category)
			formData.append('value_chains', dataForm.value_chains)
			formData.append('desc', dataForm.desc)
			formData.append('video_link', dataForm.video_link)
			formData.append('webimages', images)
			axios.post(`${strings.url.content_url}/webimages/create`, formData, {
			// axios.post(`http://localhost:8500/webimages/create`, formData, {
				headers: {
					'content-type': 'multipart/form-data',
					"Access-Control-Allow-Origin": "*"
				}
			}).then(doc => {
				console.log(doc.data)
				if (doc.data.error === false) {
					message.success(`${strings.response.success}`)
					// window.location.href = "/app/webimages/product-list"
					history.push("/app/webimages/product-list")
				}
			}).catch(e => {
				console.log('error: ', e.message)
			})
		}
		if (mode === EDIT) {
			message.success(`Image saved`);
		}
		event.preventDefault()
	};

	const handleChange = (event) =>{
		setDataForm({
			...dataForm,
			[event.target.name]:event.target.value
		})
		console.log(dataForm)
	}

	return (
		<>
			<form
				layout="vertical"
				onSubmit={onSubmitHandle}
				name="advanced_search"
				className="ant-advanced-search-form"
			>
				<PageHeaderAlt className="bg-white border-bottom" overlap>
					<div className="container">
						<Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
							<h2 className="mb-3">{mode === 'ADD' ? 'Add New Image' : `Edit Image`} </h2>
							<div className="mb-3">
								<Button className="mr-2">Discard</Button>
								<Button type="primary" htmlType="submit" >
									{mode === 'ADD' ? 'Add' : `Save`}
								</Button>
							</div>
						</Flex>
					</div>
				</PageHeaderAlt>
				<div className="container">
					<Tabs defaultActiveKey="1" style={{ marginTop: 30 }}>
						<TabPane tab="General" key="1">
							<Row gutter={16}>
								<Col xs={24} sm={24} md={17}>
									<Card title="Basic Info">
										<label>Name</label>
										<Input name="name" onChange={handleChange} />
										<br/>
										<br/>
										<label>Category</label>
										<select name="category" onChange={handleChange} style={{width:"100%"}}>
											<option value="general">General</option>
											<option value="image">Image</option>
											<option value="video">Video</option>
											<option value="location">Location</option>
										</select>
										<br/>
										<br/>
										<label>Value Chains</label>
										<select name="value_chains" onChange={handleChange} style={{width:"100%"}}>
											<option value="general">General</option>
											<option value="shrimp">Shrimp</option>
											<option value="seaweed">Seaweed</option>
											<option value="milkfish">Milkfish</option>
											<option value="ccatfish">Catfish</option>
											<option value="pangasius">Pangasius</option>
										</select>
										<br/>
										<br/>
										<label>Video Link</label>
										<Input name="video_link" onChange={handleChange} placeholder="Video Link" />
										<br/>
										<br/>
										<label>Description</label>
										<TextArea rows={10} name="desc" onChange={handleChange} placeholder="Description" />
									</Card>
									<Card title="Dimension">
										<Row gutter={16}>
											<Col xs={24} sm={24} md={12}>
												<Input name="width" onChange={handleChange} placeholder="Width" />
											</Col>
											<Col xs={24} sm={24} md={12}>
												<Input name="height" onChange={handleChange} placeholder="Height" />
											</Col>
										</Row>
									</Card>
								</Col>
								<Col xs={24} sm={24} md={7}>
									<Card title="Media">
										<Input required onChange={pickImageHandler} name="resources" accept=".png,.jpeg,.jpg" type="file" placeholder="PDF"></Input>
										{mode === 'ADD' ? '' : <img style={{ width: "100%", padding: "5%" }} src="https://gqspindonesia.org/Shrimp@2x.png" ></img>}
									</Card>
								</Col>
							</Row>
						</TabPane>
					</Tabs>
				</div>
			</form>
		</>
	)
}

export default ProductForm
