export const strings = {
  MapboxApiKey:'pk.eyJ1Ijoic2FuZ3Nha2F3aXJhIiwiYSI6ImNqdXBhajZmeTBudXg0NG50YjdhcDF2amUifQ.NmC56k1T54xEKGmlrFOxRA',
  SECRET_KEY:'adh8o!8asdn2129&askjjf2y2ttty12877',
    auth_form: {
      isUnavailableAccount: "Don't have an account yet?",
      alreadyHaveAccount:"Already Have an Account?",
      sign_up: "Sign Up",
      sign_in: "Sign In",
      create_account: "Create a new account:",
    },
    category: {
      stories: [
          {
              id: 'Impact',
              name: 'Impact'
          },
          {
              id: 'News',
              name: 'News'
          },
          {
              id: 'Press',
              name: 'Press'
          }
      ],
      events: [
          {
              id: 'Workshop',
              name: 'Workshop'
          },
          {
              id: 'Seminar',
              name: 'Seminar'
          },
          {
              id: 'News',
              name: 'News'
          }
      ],
      resources: [
          {
              id: 'Publications',
              name: 'Publications'
          },
          {
              id: 'Newsletters',
              name: 'Newsletters'
          },
          {
              id: 'Manuals',
              name: 'Manuals'
          }
      ],
    },
    option: {
      update: 'Update',
      detail: 'View Detail',
      remove: 'Delete',
      add: 'Add',
    },
    month: ["January", "February", "March", "April", "May","June","July", "August", "September", "October", "November","December"],
    default: {
        subscriber: "Subscriber",
        title_app: "GQSP Indonesia",
        add_events: "Add Event",
        add_images: "Add Image",
        please_select: "Please select",
        author: {
          label: "Author",
          name: "author"
        },
        media_pdf: "Media Pdf",
        media_image: "Media Image",
        add_resources: 'Add Resource',
        add_stories: 'Add Story',
        add_users: 'Add User',
        indonesia: 'Indonesia',
        english: 'English',
        additional_info: 'Additional Information',
        name_users: 'Name Users',
        email_users: 'Email Users',
        gender: 'Gender',
        city: 'City',
        institution: 'Institution',
        created_date: 'Created Date',
        path: 'Path',
        date_event: {
          name: 'date_event',
          label: 'Date Event'
        },
        confirmation: {
          is_confirmation: 'Confirmation',
          is_delete: 'Do you want to delete these items?',
          is_add: 'Do you want to add these items?'
        },
        id: {
          desc: {
            label: 'Deskripsi',
            name: 'description_idn'
          },
          title: {
            label: 'Judul',
            name: 'title_idn'
          },
          markdown: {
            label: 'Markdown',
            name: 'markdown_idn',
          }
        },
        en: {
          title: "Title",
          title_: {
            label: 'Title',
            name: 'title_en'
          },
          title_idn: {
            label: "Title In Bahasa",
            name: "Title_IDN",
          },
          desc: {
            label: 'Description',
            name: 'description_en'
          },
          link: 'Link',
          website: "Website",
          time: "Time",
          time_before: {
            name: 'time_before',
            label: 'Time Before',
          },
          time_after: {
            name: 'time_after',
            label: 'Time After'
          },
          time_before_after: "Time Before & After",
          date: "Date",
          categories: "Categories",
          image: "Image",
          information: "Information",
          id: "ID",
          categories_idn: 'Categories IDN',
          categories_en: 'Categories EN',
          created_date: 'Created Date',
          date_event: 'Date Event',
          discard: 'Discard',
          save: 'Save',
          file_pdf: {
            name: 'file_pdf',
            label: 'File Pdf'
          },
          name_users: {
            name: 'name_users',
            label: 'Name Users'
          },
          email_users: {
            name: 'email_users',
            label: 'Email Users',
          },
          password_users: {
            name: 'password_users',
            label: 'Password Users',
          },
          confirm_password_users: {
            name: 'confirm_password_users',
            label: 'Confirmation Password Users',
          },
          markdown: {
            name: 'markdown_en',
            label: 'Markdown',
          },
          name: {
            name: 'name',
            label: 'Name',
          },
          category: {
            name: 'category',
            label: 'Category',
          },
          width: {
            name: 'width',
            label: 'Width'
          },
          height: {
            name: 'height',
            label: 'Height'
          }
        }
    },
    response: {
        success: 'Success',
        isuploaded_img: 'Upload your image',
        isuploaded_pdf: 'Upload your .pdf file',
        ischoose_categories: 'Choose your categories',
        upload_succeed: 'file uploaded successfully.',
        upload_failed: 'file upload failed.',
        confirm_password_isvalid: 'Confirmation Password Is Not Valid',
        removed_first: 'Please, Remove First To Change The Image',
    },
    imageType: {
      events: '2',
      resources: '3',
      stories: '4',
    },
    url: {
      base_url: 'https://gqsp.codedirect-project.com/public/',
      proxy_url: 'https://cors-anywhere.herokuapp.com/',
      // local_url: 'http://localhost:8500/',
      content_url: 'https://gqspindonesia.org',
      // content_url_port: 'https://gqspindonesia.org:8500/',
      // content_url: 'http://localhost:8500',
      token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9ncXNwLmNvZGVkaXJlY3QtcHJvamVjdC5jb21cL3B1YmxpY1wvYXBpXC9sb2dpbiIsImlhdCI6MTYwNDU4MDkxNiwiZXhwIjo3NjA0NTgwODU2LCJuYmYiOjE2MDQ1ODA5MTYsImp0aSI6IjBtQkRrRFM5czJ4QUpUUDkiLCJzdWIiOjEsInBydiI6Ijg3ZTBhZjFlZjlmZDE1ODEyZmRlYzk3MTUzYTE0ZTBiMDQ3NTQ2YWEifQ.RydDWxGBfqjOWUC2AE8b-AvVjDMW2BXkPw_A63Z4W40',
      dragger_url: 'https://run.mocky.io/v3/2cc49898-9375-439c-bcd0-914e0366d5dd',
    },
    menu: {
      pages: 'Pages',
      menus: 'Menus',
      home: 'Home',
      elearning: 'E-Learning',
      analysis: 'Analysis',
      subscriber:"Subscriber",
      events: 'Events',
      resources: 'Resources',
      stories: 'Stories',
      Categories: 'Categories',
      homePage: 'Home Page',
      aboutPage: 'About Page',
      valuesPage: 'Value Page',
      resourcesPage: 'Resources Page',
      eventsPage: 'Events Page',
      storiesPage: 'Stories Page',
      accountSetting: 'Account Setting',
      helpCenter: 'Help Center',
      config: 'Configuration',
      management: 'Management',
      users: 'Users',
      images: 'Images',
      dashboard: 'Dashboard',
      editResource: 'Edit Resource',
      editStory: 'Edit Story',
      editEvent: 'Edit Event',
      design: 'Web Design',
    },
    navigation: {
      login: '/auth/login',
      register: '/auth/register',
      main: '/app',
      page: {
        home: '/app/home',
        // Main Pages
        homePage: '/app/home-page',
        categoriesPage: '/app/categories-page',
        storiesPage: '/app/stories-page',
        aboutPage: '/app/about-page',
        valuesPage: '/app/values-page',
        resourcesPage: '/app/resources-page',
        eventsPage: '/app/events-page',
        resources: '/app/resources',
        events: '/app/events',
        stories: '/app/stories',
        addEvents: '/app/add-events',
        addResources: '/app/add-resources',
        addStories: '/app/add-stories',
        addUsers: '/app/add-users',
        addTicket:"/app/add-ticket",
        addLocation:"/app/add-location",
        messages:"/app/messages",
        editResource: '/app/edit-resource',
        value_chains:'/app/value-chains',
        editStory: '/app/story',
        editEvent: '/app/event',
        users: '/app/users',
        images: '/app/images',
        add_value_chains:'/app/add-value-chains',
        desain: '/app/desain',
        tickets:'/app/tickets',
        locations:'/app/locations',
        webimages:"/app/webimages",
        wordpress:"/app/wordpress",
        subscribers:"/app/subscribers",
        subscribers_analytic:'/app/subscribers-analytic',
        add_management_images:'/app/add-management-images',
        detail_message:"/app/detail-message",
        notification:"/app/notification",
        detail_notification:"/app/detail-notification",
      },
      path: {
        login: 'login',
        register: 'register',
        forgot_password: 'forgot-password',
        error_one: 'error-1',
        error_two: 'error-2',
        home: 'home',
        add_value_chains:'add-value-chains',
        events: 'events',
        resources: 'resources',
        stories: 'stories',
        locations: 'locations',
        value_chains:'value-chains',
        categories: 'categories',
        add_events: 'add-events',
        add_resources: 'add-resources',
        add_stories: 'add-stories',
        add_users: 'add-users',
        add_images: 'add-images',
        add_ticket:"add-ticket",
        add_location:"add-location",
        read_messages:"read-messages",
        messages:"messages",
        detail_message:"detail-message",
        notification:"notification",
        detail_notification:"detail-notification",
        // Main Pages
        homePage: 'home-page',
        storiesPage: 'stories-page',
        aboutPage: 'about-page',
        valuesPage: 'values-page',
        categoriesPage: 'categories-page',
        add_categories: 'add-categories',
        resourcesPage: 'resources-page',
        eventsPage: 'events-page',
        accountProfile: 'account-profile',
        tickets:'tickets',
        users: 'users',
        images: 'images',
        webimages: 'webimages',
        desain: 'desain',
        subscribers:'subscribers',
        subscribers_analytic:'subscribers-analytic',
        // Edit
        edit_resource: 'edit-resource',
        edit_story: 'edit-story',
        edit_event: 'edit-event',
        wordpress: 'wordpress',
        add_management_images: 'add-management-images',
      },
      submenu: {
        categories_event: 'categories-event',
        categories_resources: 'categories-resources',
        categories_stories: 'categories-stories',
        add_categories: 'add-categories',
      }
    }
};
  