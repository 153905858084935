import { Button, Card, Col, Image, Input, message, Row } from 'antd';
import Axios from 'axios';
import { PageHeaderAlt } from 'components/layout-components/PageHeaderAlt';
import Flex from 'components/shared-components/Flex';
import React, { useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useHistory, useLocation } from 'react-router-dom';
import { dimens, strings } from 'res';
import TextArea from 'antd/lib/input/TextArea';

const AddLocation = () => {

    const [links, setLinks] = useState([]);
    const [dataImage, setDataImage] = useState({});
    const history = useHistory();
    const [count, setCount] = useState(0);
    const location = useLocation();
    const [dataForm, setDataForm] = useState({
        name: "",
        name_eng: "",
        id:"",
        primary_image: "",
        theme_color:"",
        images: [],
        deskripsi: "",
        deskripsi_eng: ""
    })
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        if (location.state.param === "Update") {
            getDataDetail(location.state.id)
        }
    }, [])

    const getDataDetail = (value) => {
        Axios.get(`${strings.url.content_url}/chains/readById/${value._id}`, {
            headers: {
                "Access-Control-Allow-Origin":"*"
            }
        }).then(doc=>{
            let response = doc.data.doc[0]
            setLinks(response.images)
            SpreadUpdateData(response.images)
            setDataForm({
                name: response.name,
                id:response._id,
                name_eng: response.name_eng,
                primary_image: response.primary_image,
                theme_color:response.theme_color,
                images: response.images,
                videos: response.videos,
                images_description: response.images_description,
                images_description_eng: response.images_description_eng,
                deskripsi: response.deskripsi,
                deskripsi_eng: response.deskripsi_eng
            })
        })
    }

    const SpreadUpdateData = (array)=>{
        console.log(array)
        array.map((data,row)=>{
            dataImage[`images-${row}`]=data.image
            dataImage[`videos-${row}`]=data.video
            dataImage[`images_description-${row}`]=data.images_description
            dataImage[`images_description_eng-${row}`]=data.images_description_eng
        })
    }

    const handleOnChange = (event) => {
        setDataForm({
            ...dataForm,
            [event.target.name]: event.target.value
        })
    }

    const handleImagesChange = (event) => {
        setDataImage({
            ...dataImage,
            [event.target.name]: event.target.value 
        })
    }

    const validateImages = new Promise((resolve,reject)=>{
        let images = []
        let count = 0
        let iterate = 0
        for (var key in dataImage) {
            if (dataImage.hasOwnProperty(key)) {
                iterate++
                if(iterate === 4){
                    images = [
                        ...images,
                        {
                            image:dataImage[`images-${count}`] || "",
                            video:dataImage[`videos-${count}`] || "",
                            images_description:dataImage[`images_description-${count}`] || "",
                            images_description_eng:dataImage[`images_description_eng-${count}`] || ""
                        }
                    ]
                    count++
                    iterate=0
                }
            }
        }
        resolve(images)
    })

    const onSubmitInd = async (event) => {
        
        validateImages.then(images=>{
            setSubmitLoading(true)
        if (location.state.param === "Update") {
            Axios.post(`${strings.url.content_url}/chains/update`,{
                ...dataForm,
                _id:dataForm.id,
                images
            }, {
                headers: {
                    'content-type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                }
            }).then(doc=>{
                if (doc.data.error === false) {
                    setSubmitLoading(false)
                    message.success(`${strings.response.success}`)
                    history.goBack()
                }
            })
        }
        else{
            Axios.post(`${strings.url.content_url}/chains/create`,{
                ...dataForm,
                images
            }, {
                headers: {
                    'content-type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                }
            }).then(doc=>{
                if (doc.data.error === false) {
                    setSubmitLoading(false)
                    message.success(`${strings.response.success}`)
                    history.goBack()
                }
            })
        }
        }).catch(err=>{
            message.error("Error")
        })
        
        event.preventDefault()
    }

    const tambahLink = () => {
        let counts = count + 1
        setCount(counts)
        setLinks([...links, { id: count, images: "", videos: "", images_description: "", images_description_eng: "" }])
    }

    const deleteItem = (index) => {
        const filtered = links.filter((link, idx) => idx !== index)
        delete dataImage[`images-${index}`]
        delete dataImage[`videos-${index}`]
        delete dataImage[`images_description-${index}`]
        delete dataImage[`images_description_eng-${index}`]
        setLinks(filtered)
    }

    const renderLinks = () => {
        return links.map((row, idx) => {
            return (
                <div key={idx}>
                    <br />
                    <Row gutter={24} style={{ border: "1px" }}>
                        <Col md={3}>
                            <label>Photo-{idx}</label>
                        </Col>
                        <Col md={15}>
                            <label>URL</label>
                            <Input required={true} idx={idx} value={dataImage[`images-${idx}`]} onChange={handleImagesChange} name={`images-${idx}`} placeholder="Image URL"></Input>
                            <br />
                            <br />
                            <label>Video Link (type </label><label style={{color:"red"}}>"No_Video"</label><label> if there is no video)</label>
                            <Input required={true} idx={idx} value={dataImage[`videos-${idx}`]} onChange={handleImagesChange} name={`videos-${idx}`} placeholder="Video URL"></Input>
                            <br />
                            <br />
                            <label>Deskripsi (Indonesia)</label>
                            <TextArea required={true} idx={idx} value={dataImage[`images_description-${idx}`]} onChange={handleImagesChange} rows={2} name={`images_description-${idx}`} placeholder="Deskripsi"></TextArea>
                            <br />
                            <br />
                            <label>Description (English)</label>
                            <TextArea required={true} idx={idx} value={dataImage[`images_description_eng-${idx}`]} onChange={handleImagesChange} rows={2} name={`images_description_eng-${idx}`} placeholder="Description"></TextArea>
                        </Col>
                        <Col md={6}>
                            <Button onClick={() => deleteItem(idx)} type="danger" style={{ color: "#FFF" }}>Delete</Button>
                            <br></br>
                            <br></br>
                            <Image src={dataImage[`images-${idx}`]}></Image>
                        </Col>
                    </Row>
                </div>
            )
        })
    }

    return (
        <form
            layout="vertical"
            onSubmit={onSubmitInd}
            method="POST"
            name="advanced_search"
            className="ant-advanced-search-form"
        >
            <PageHeaderAlt className="bg-white border-bottom" overlap>
                <div className="container">
                    <Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
                        <h2 className="mb-3">{strings.default.en.add_resources}</h2>
                        <div className="mb-3">
                            <Button className="mr-2" onClick={() => history.goBack()}> Back </Button>
                            <Button type="primary" htmlType="submit" loading={submitLoading} >
                                {strings.default.en.save}
                            </Button>
                        </div>
                    </Flex>
                </div>
            </PageHeaderAlt>
            <div className="container" style={{ marginTop: dimens.medium }}>
                <Col>
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={24}>
                            <Card title={`${location.state.param}`}>
                                <label>Nama (Indonesia)</label>
                                <Input value={dataForm.name} type="text" onChange={handleOnChange} name="name" placeholder="Nama" />
                                <br />
                                <br />
                                <label>Name (English)</label>
                                <Input value={dataForm.name_eng} type="text" onChange={handleOnChange} name="name_eng" placeholder="Nama" />
                                <br />
                                <br />
                                <label>URL Foto Utama</label>
                                <Input value={dataForm.primary_image} type="text" onChange={handleOnChange} name="primary_image" placeholder="URL Foto Utama" />
                                <br />
                                <br />
                                <label>Theme Color Hex</label>
                                <Input value={dataForm.theme_color} type="text" onChange={handleOnChange} name="theme_color" placeholder="Theme Color" />
                                <br />
                                <br />
                                <label>Foto-foto</label>
                                <Button type="primary" style={{ width: "100%" }} onClick={tambahLink}>Tambah Foto</Button>
                                <br />
                                {renderLinks()}
                                <br />
                                <br />
                                <label>Deskripsi</label>
                                <TextArea value={dataForm.deskripsi} onChange={handleOnChange} name="deskripsi" rows={5} placeholder="Description"></TextArea>
                                <br />
                                <br />
                                <label>Description</label>
                                <TextArea value={dataForm.deskripsi_eng} onChange={handleOnChange} name="deskripsi_eng" rows={5} placeholder="Description"></TextArea>
                                <br />
                                <br />
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </div>
        </form>
    )
}

export default AddLocation
