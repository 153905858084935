import { Button, Card, Col, Form, Input, message, Row, Select } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import Axios from 'axios';
import { PageHeaderAlt } from 'components/layout-components/PageHeaderAlt';
import Flex from 'components/shared-components/Flex';
import React, { useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useHistory, useLocation } from 'react-router-dom';
import { dimens, strings } from 'res';
import ReactMapGL,{Marker} from 'react-map-gl';
import { PinDrop } from '@material-ui/icons';
import TextArea from 'antd/lib/input/TextArea';

const AddLocation = () => {

    const { Option, OptGroup } = Select;
    let history = useHistory();

    const [dataImage, setDataImage] = useState({});
    const [links, setLinks] = useState([]);
    const location = useLocation();
    const [count, setCount] = useState(0);
    const [dataForm,setDataForm] = useState({
        id:"",
        name: "",
        city: "",
        kapasitas: "",
        produksi:"",
        spesies: "",
        url_foto:"",
        desc: "",
        desc_en: "",
        images:[],
        category: "Shrimp",
        latitude: "",
        longitude: ""
    })
    const [submitLoading, setSubmitLoading] = useState(false);

    const [idItem, setIdItem] = useState();

    const [viewport, setViewport] = useState({
        width: '100%',
        height: '40vw',
        latitude: -2.971061,
        longitude: 119.6282669,
        zoom: 4
      });

    useEffect(() => {
        if (location.state.id) {
            getDataDetail(location.state.id)
        } 
    }, []) 

    const getDataDetail = (value) => {
        if(value._id){
            Axios.get(`${strings.url.content_url}/location/readById/${value._id}`, {
                headers: {
                    "Access-Control-Allow-Origin":"*"
                }
            }).then(doc=>{
                console.log(doc.data.doc[0])
                setIdItem(doc.data.doc[0]._id);
                SpreadUpdateData(doc.data.doc[0].images)
                setLinks(doc.data.doc[0].images)
                setDataForm({
                    ...dataForm,
                    name: doc.data.doc[0].name,
                    city: doc.data.doc[0].city,
                    kapasitas: doc.data.doc[0].kapasitas,
                    produksi:doc.data.doc[0].produksi,
                    spesies: doc.data.doc[0].spesies,
                    url_foto:doc.data.doc[0].url_foto,
                    desc: doc.data.doc[0].desc,
                    desc_en: doc.data.doc[0].desc_en,
                    images:doc.data.doc[0].images,
                    category: doc.data.doc[0].category
                })
                setViewport({
                    ...viewport,
                    latitude:parseFloat(doc.data.doc[0].latitude),
                    longitude:parseFloat(doc.data.doc[0].longitude)
                })
            })
        }
    }

    const SpreadUpdateData = (array)=>{
        console.log(array)
        array.map((data,row)=>{
            dataImage[`images-${row}`]=data.image
            dataImage[`videos-${row}`]=data.video
            dataImage[`images_description-${row}`]=data.images_description
            dataImage[`images_description_eng-${row}`]=data.images_description_eng
        })
    }

    const handleOnChange = (event) => {
        setDataForm({
            ...dataForm,
            [event.target.name]: event.target.value
        })
    }

    const handleImagesChange = (event) => {
        setDataImage({
            ...dataImage,
            [event.target.name]: event.target.value 
        })
    }

    const onSubmitInd = (event) => {
        setSubmitLoading(true)
        let images = []
        let count = 0
        let iterate = 0
        for (var key in dataImage) {
            if (dataImage.hasOwnProperty(key)) {
                iterate++
                if(iterate === 4){
                    images = [
                        ...images,
                        {
                            image:dataImage[`images-${count}`],
                            video:dataImage[`videos-${count}`],
                            images_description:dataImage[`images_description-${count}`],
                            images_description_eng:dataImage[`images_description_eng-${count}`]
                        }
                    ]
                    count++
                    iterate=0
                }
            }
            
        }

        let formData = new FormData()

        formData.append('name', dataForm.name)
        formData.append('city', dataForm.city)
        formData.append('category', dataForm.category)
        formData.append('desc', dataForm.desc)
        formData.append('desc_en', dataForm.desc_en)
        formData.append('produksi', dataForm.produksi)
        formData.append('kapasitas', dataForm.kapasitas) 
        formData.append('spesies', dataForm.spesies)
        formData.append('url_foto', dataForm.url_foto)
        formData.append('latitude', viewport.latitude)
        formData.append('longitude', viewport.longitude)
        if (location.state.param === "Update") {
            Axios.post(`${strings.url.content_url}/location/update`, {
                ...dataForm,
                id:idItem,
                latitude:viewport.latitude,
                longitude:viewport.longitude,
                images
            }, {
                headers: {
                    'content-type': 'application/json',
                    "Access-Control-Allow-Origin":"*"
                }
            }).then(doc => {
                if (doc.data.error === false) {
                    setSubmitLoading(false)
                    message.success(`${strings.response.success}`)
                    history.goBack()
                }
            }).catch(e => {
                console.log('error: ', e.message)
            })
        }else{
            Axios.post(`${strings.url.content_url}/location/create`, {
                ...dataForm,
                latitude:viewport.latitude,
                longitude:viewport.longitude,
                images
            }, {
                headers: {
                    'content-type': 'application/json',
                    "Access-Control-Allow-Origin":"*"
                }
            }).then(doc => {
                if (doc.data.error === false) {
                    setSubmitLoading(false)
                    message.success(`${strings.response.success}`)
                    history.goBack()
                }
            }).catch(e => {
                console.log('error: ', e.message)
            })
        }
        
        event.preventDefault()
    }

    const handleOnChangeSelect = (value) => {
        setDataForm({
            ...dataForm,
            category: value
        })
    }

    const tambahLink = () => {
        let counts = count + 1
        setCount(counts)
        setLinks([...links, { id: count, images: "", videos: "", images_description: "", images_description_eng: "" }])
    }

    const deleteItem = (index) => {
        const filtered = links.filter((link, idx) => idx !== index)
        delete dataImage[`images-${index}`]
        delete dataImage[`videos-${index}`]
        delete dataImage[`images_description-${index}`]
        delete dataImage[`images_description_eng-${index}`]
        setLinks(filtered)
    }

    const renderLinks = () => {
        return links.map((row, idx) => {
            return (
                <div key={idx}>
                    <br />
                    <Row gutter={24} style={{ border: "1px" }}>
                        <Col md={3}>
                            <label>Photo-{idx}</label>
                        </Col>
                        <Col md={15}>
                            <label>URL</label>
                            <Input idx={idx} value={dataImage[`images-${idx}`]} onChange={handleImagesChange} name={`images-${idx}`} placeholder="Image URL"></Input>
                            <br />
                            <br />
                            <label>Videos (Optional)</label>
                            <Input idx={idx} value={dataImage[`videos-${idx}`]} onChange={handleImagesChange} name={`videos-${idx}`} placeholder="Video URL"></Input>
                            <br />
                            <br />
                            <label>Deskripsi (Indonesia)</label>
                            <TextArea idx={idx} value={dataImage[`images_description-${idx}`]} onChange={handleImagesChange} rows={2} name={`images_description-${idx}`} placeholder="Deskripsi"></TextArea>
                            <br />
                            <br />
                            <label>Description (English)</label>
                            <TextArea idx={idx} value={dataImage[`images_description_eng-${idx}`]} onChange={handleImagesChange} rows={2} name={`images_description_eng-${idx}`} placeholder="Description"></TextArea>
                        </Col>
                        <Col md={6}>
                            <Button onClick={() => deleteItem(idx)} type="danger" style={{ color: "#FFF" }}>Delete</Button>
                        </Col>
                    </Row>
                </div>
            )
        })
    }

    return (
        <form
            layout="vertical"
            onSubmit={onSubmitInd}
            method="POST"
            name="advanced_search"
            className="ant-advanced-search-form"
        >
            <PageHeaderAlt className="bg-white border-bottom" overlap>
                <div className="container">
                    <Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
                        <h2 className="mb-3">{strings.default.en.add_resources}</h2>
                        <div className="mb-3">
                            <Button className="mr-2" onClick={() => history.goBack()}> {strings.default.en.discard} </Button>
                            <Button type="primary" htmlType="submit" loading={submitLoading} >
                                {strings.default.en.save}
                            </Button>
                        </div>
                    </Flex>
                </div>
            </PageHeaderAlt>
            <div className="container" style={{marginTop: dimens.medium}}>
                <Col>
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={24}>
                            <Card title="Add New Location">
                                <label>Nama Tempat</label>
                                <Input value={dataForm.name} type="text" onChange={handleOnChange} name="name" placeholder="Nama" />
                                <br/>
                                <br/>
                                <label>Kabupaten/Kota</label>
                                <Input value={dataForm.city} type="text" onChange={handleOnChange} name="city" placeholder="Kota" />
                                <br/>
                                <br/>
                                <label>Produksi(Kg)</label>
                                <Input value={dataForm.produksi} type="text" onChange={handleOnChange} name="produksi" placeholder="Produksi" />
                                <br/>
                                <br/><label>Kapasitas Produksi(Kg)</label>
                                <Input value={dataForm.kapasitas} type="text" onChange={handleOnChange} name="kapasitas" placeholder="Kapasitas Produksi" />
                                <br/>
                                <br/>
                                <label>Rantai Nilai</label>
                                <Select defaultValue="Shrimp" onChange={handleOnChangeSelect} style={{ width: "100%" }} name="category" type="text" placeholder="Category">
                                    <Option value="Shrimp">Shrimp</Option>
                                    <Option value="Catfish">Catfish</Option>
                                    <Option value="Seaweed">Seaweed</Option>
                                    <Option value="Milkfish">Milkfish</Option>
                                    <Option value="Pengasius">Pengasius</Option>
                                    {/* <Option value="SmartFish">SMART-Fish</Option> */}
                                </Select>
                                <br/>
                                <br/>
                                <label>Spesies Rantai Nilai</label>
                                <Input value={dataForm.spesies} type="text" onChange={handleOnChange} name="spesies" placeholder="Spesies" />
                                <br/>
                                <br/>
                                <label>URL Foto Utama</label>
                                <Input value={dataForm.url_foto} type="text" onChange={handleOnChange} name="url_foto" placeholder="URL Foto Utama" />
                                <br/>
                                <br/>
                                <label>Foto-foto</label>
                                <Button type="primary" style={{ width: "100%" }} onClick={tambahLink}>Tambah Foto</Button>
                                <br />
                                {renderLinks()}
                                <br />
                                <br />
                                <label>Deskripsi Indonesia</label>
                                <TextArea value={dataForm.desc} onChange={handleOnChange} name="desc" rows={5} placeholder="Deskripsi"></TextArea>
                                <br/>
                                <br/>
                                <label>Description English</label>
                                <TextArea value={dataForm.desc_en} onChange={handleOnChange} name="desc_en" rows={5} placeholder="Description"></TextArea>
                                <br/>
                                <br/>
                                <ReactMapGL
                                mapboxApiAccessToken={strings.MapboxApiKey}
                                    {...viewport}
                                    onViewportChange={nextViewport => setViewport(nextViewport)}
                                >
                                    <label>Latitude: {viewport.latitude}</label>
                                    <br/>
                                    <label>Longitude: {viewport.longitude}</label>
                                    <Marker
                                        latitude={viewport.latitude}
                                        longitude={viewport.longitude}
                                        offsetLeft={-15}
                                        offsetTop={-30}
                                        onDragEnd={event => {
                                            let [lng, lat] = event.lngLat;
                                            setViewport({
                                                ...viewport,
                                                latitude: lng,
                                                longitude: lat,
                                            })
                                        }}
                                    >
                                        <PinDrop></PinDrop>
                                    </Marker>
                                </ReactMapGL>
                                <br/>
                                <br/>
                                <Input type="text" onChange={handleOnChange} name="latitude" value={viewport.latitude} placeholder="Latitude" />
                                <br/>
                                <br/>
                                <Input type="text" onChange={handleOnChange} name="longitude" value={viewport.longitude} placeholder="Longitude" />
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </div>
        </form>
    )
}

export default AddLocation
