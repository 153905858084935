import React from 'react';
import ProductForm from '../ProductForm';

const AddProduct = () => {
	return (
		<ProductForm mode="ADD"/>
	)
}

export default AddProduct
