import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { LockOutlined, MailOutlined, ProfileOutlined } from '@ant-design/icons';
import { Button, Form, Input, Alert } from "antd";
import { showAuthMessage, showLoading, hideAuthMessage, authenticated } from 'redux/actions/Auth';
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion"
import { strings } from 'res';
import axios from 'axios'

const rules = {
	username: [
		{ 
			required: true,
			message: 'Please input your email address'
		},
	],
	email: [
		{ 
			required: true,
			message: 'Please input your email address'
		},
		{ 
			type: 'email',
			message: 'Please enter a validate email!'
		}
	],
	password: [
		{ 
			required: true,
			message: 'Please input your password'
		}
	],
	confirm: [
		{ 
			required: true,
			message: 'Please confirm your password!'
		},
		({ getFieldValue }) => ({
			validator(rule, value) {
				if (!value || getFieldValue('password') === value) {
					return Promise.resolve();
				}
				return Promise.reject('Passwords do not match!');
			},
		})
	]
}

export const RegisterForm = (props) => {

	const { showLoading, loading, message, showMessage,
		showAuthMessage } = props
	const [form] = Form.useForm();
	let history = useHistory();

	const handleValidSubmit = (values)=>{
		showLoading()
		axios.post(`${strings.url.content_url}/users/register`, {
		  username: values.username,
		  email: values.email,
		  password: values.password
		}, {
		  headers: {
			"Content-Type": "application/json"
		  }
		}).then(doc => {
			console.log(doc.data)
			if (doc.data.status) {
				showLoading()
				localStorage.setItem('token', doc.data.token)
				history.push("/app/home")
			} else {
				showAuthMessage(doc.data.message)
			}
		})
	  }

	useEffect(() => {
    	if (localStorage.getItem('token') !== null) {
			history.push("/app/home")
		}
  });
	
	return (
		<>
			<motion.div 
				initial={{ opacity: 0, marginBottom: 0 }} 
				animate={{ 
					opacity: showMessage ? 1 : 0,
					marginBottom: showMessage ? 20 : 0 
				}}> 
				<Alert type="error" showIcon message={message}></Alert>
			</motion.div>
				<Form form={form} layout="vertical" name="register-form" onFinish={handleValidSubmit}>
				<Form.Item 
					name="username" 
					label="username" 
					rules={rules.username}
					hasFeedback
				>
				<Input prefix={<ProfileOutlined className="text-primary" />}/>
				</Form.Item>
				<Form.Item 
					name="email" 
					label="Email" 
					rules={rules.email}
					hasFeedback
				>
				<Input prefix={<MailOutlined className="text-primary" />}/>
				</Form.Item>
				<Form.Item 
					name="password" 
					label="Password" 
					rules={rules.password}
					hasFeedback
				>
				<Input.Password prefix={<LockOutlined className="text-primary" />}/>
				</Form.Item>
				<Form.Item 
					name="confirm" 
					label="ConfirmPassword" 
					rules={rules.confirm}
					hasFeedback
				>
					<Input.Password prefix={<LockOutlined className="text-primary" />}/>
				</Form.Item>
				<Form.Item>
					<Button type="primary" style={{background:"#63376A",border:"0px"}} htmlType="submit" block loading={loading}>
						Sign Up
					</Button>
				</Form.Item>
			</Form>
		</>
	)
}

const mapStateToProps = ({auth}) => {
	const { loading, message, showMessage, token, redirect } = auth;
  return { loading, message, showMessage, token, redirect }
}

const mapDispatchToProps = {
	showAuthMessage,
	hideAuthMessage,
	showLoading,
	authenticated
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm)
