import React, { Component } from "react";
import axios from 'axios'
import { Input,Button,Form,Modal } from "antd";
import { strings } from 'res';
import TextArea from "antd/lib/input/TextArea";

export class Basic extends Component {
  constructor(props){
    super(props)
    this.state = {
      homePageInd:{},
      visible:false,
      updateMessage:""
    }
    this.handleChange = this.handleChange.bind(this);
    this.onFinish = this.onFinish.bind(this);
    this.showModal = this.showModal.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  componentDidMount(){
    axios.get(`${strings.url.content_url}/resourcesPage/read`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin":"*"
      }
    }).then(doc=>{
      if(this.props.bahasa === "Indonesia"){
        this.setState({
          homePageInd:doc.data.resourcesInd[0]
        })
      }else{
        this.setState({
          homePageInd:doc.data.resourcesEng[0]
        })
      }
     
    })
  }

  handleChange(event){
    this.setState({
      homePageInd:{
        ...this.state.homePageInd,
        [event.target.name]: event.target.value
       }
    })
  }

   onFinish = (values) =>{
    console.log(this.state)
    if(this.props.bahasa === "Indonesia"){
      axios.post(`${strings.url.content_url}/resourcesPage/updateIndonesia`,this.state.homePageInd, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin":"*"
        }
      }).then(doc=>{
        this.setState({
          visible: true,
          updateMessage:doc.data.msg
        });
      })
    }else{
      axios.post(`${strings.url.content_url}/resourcesPage/updateEnglish`,this.state.homePageInd, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin":"*"
        }
      }).then(doc=>{
        this.setState({
          visible: true,
          updateMessage:doc.data.msg
        });
      })
    }
  }

  render() {
    return (
      <div>
          <Modal
          title="Message!"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <h3 style={{textAlign:"center"}}>{this.state.updateMessage}</h3>
        </Modal>
        <h1>{this.props.bahasa}</h1>
        <Form onFinish={this.onFinish}>
        <label>Jumbotron Resources Label</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.resources} name="resources"  placeholder="Resources" />
        <br />
        <br />
        <label>Jumbotron Resources Description</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.resources_desc} name="resources_desc" placeholder="Resources Description" />    
        <br />
        <br />
        <label>Publications</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.publications} name="publications" placeholder="Publications" />
        <br />
        <br />
        <label>Manuals</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.manuals} name="manuals" placeholder="Manuals" />
        <br />
        <br />
        <label>Newsletters</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.newsletters} name="newsletters" placeholder="Newsletters" />
        <br />
        <br />
        <label>Publications Title</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.publications_head} name="publications_head" placeholder="Publications Head" />
        <br />
        <br />
        <label>Publications Desc</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.publications_desc} name="publications_desc" placeholder="Publications Description" />
        <br />
        <br />
        <label>Manuals Title</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.manuals_head} name="manuals_head" placeholder="Manuals Head" />
        <br />
        <br />
        <label>Manuals Desc</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.manuals_desc} name="manuals_desc" placeholder="Manuals Description" />
        <br />
        <br />
        <label>Newsletters Title</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.newsletters_head} name="newsletters_head" placeholder="Newsletters Head" />
        <br />
        <br />
        <label>Newsletters Desc</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.newsletters_desc} name="newsletters_desc" placeholder="Newsletters Description" />
        <br />
        <br />
        <label>Category</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.kategori} name="kategori" placeholder="Kategori" />
        <br />
        <br />
        <label>Latest Resources</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.latest_stories} name="latest_stories" placeholder="Latest Stories" />
        <br />
        <br />
        <label>Button</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.button} name="button" placeholder="Button" />
        <br />
        <br />
        <Form.Item >
          <Button htmlType="submit" type="primary" style={{width:"100%"}} >Submit</Button>
        </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Basic;
