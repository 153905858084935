import { DeleteOutlined, EyeOutlined, PlusCircleOutlined, SearchOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Input, Menu, Select, Table } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import Axios from 'axios';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import Flex from 'components/shared-components/Flex';
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import utils from 'utils';
import { strings } from '../../../res/strings';

const { Option } = Select

const Images = () => {
	let history = useHistory();
	const [selectedRows, setSelectedRows] = useState([])
	const [selectedRowKeys, setSelectedRowKeys] = useState([])

	const dropdownMenu = row => (
		<Menu>
			<Menu.Item onClick={() => onUpdate(row)}>
				<Flex alignItems="center">
					<EditOutlined />
					<span className="ml-2">{strings.option.update}</span>
				</Flex>
			</Menu.Item>
			<Menu.Item onClick={() => viewDetails(row)}>
				<Flex alignItems="center">
					<EyeOutlined />
					<span className="ml-2">{strings.option.detail}</span>
				</Flex>
			</Menu.Item>
			<Menu.Item onClick={() => deleteRow(row)}>
				<Flex alignItems="center">
					<DeleteOutlined />
					<span className="ml-2">{selectedRows.length > 0 ? `Delete (${selectedRows.length})` : `${strings.option.remove}`}</span>
				</Flex>
			</Menu.Item>
		</Menu>
	);
	
	const onAdding = () => {
		history.push({
			pathname: strings.navigation.page.add_management_images,
			state: {
				param: strings.option.add
			}
		})
	}
	
	const viewDetails = row => {
		history.push(`/app/apps/ecommerce/edit-product/${row.id}`)
	}

	const onUpdate = (row) => {
		// history.push({
		// 	pathname: strings.navigation.page.addEvents,
		// 	state: {
		// 		param: strings.option.update,
		// 		id: row
		// 	}
		// })
	}
	
	const deleteRow = row => {
		confirm({
			title: strings.default.confirmation.is_confirmation,
			content: strings.default.confirmation.is_delete,
			onOk() {
				onDelete(row);
			},
			onCancel() {},
		});
	}

	const onDelete = (row) => {
		setIsLoading(true)
		Axios.get(`${strings.url.content_url}/images/delete/${row._id}`, { headers: {
			'Content-Type': 'application/json',
			}}).then(doc => {
			if(doc.data !== null){
				getData()
			}
			setIsLoading(false)
		}).catch(e => {
			console.log(e.message)
		})
	}

	const [dataItem, setDataItem] = useState([]);
	const [dataItemBackup, setDataItemBackup] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [dataCategories, setDataCategories] = useState(strings.category.events)

	useEffect(() => {
		getData();
	}, [])

	const getData = async () => {
		let res = await Axios.get(`${strings.url.content_url}/images/read`, {
			headers: {
			  'Content-Type': 'application/json',
			}
		})
		console.log(res.data)
		setDataItemBackup(res.data.doc)
		setDataItem(res.data.doc);
		setIsLoading(false);
	}

	const tableColumns = [
		{
			title: `${strings.default.en.image}`,
			dataIndex: 'path',
			render: (_, record) => {
				return (
					<div className="d-flex">
						<AvatarStatus size={60} type="square" src={record.path}/>
					</div>
				)
			},
			sorter: (a, b) => utils.antdTableSorter(a, b, 'image')
		},
		{
			title: `${strings.default.en.title}`,
			dataIndex: 'name',
			sorter: (a, b) => utils.antdTableSorter(a, b, 'name')
		},
		{
			title: `${strings.default.en.category.label}`,
			dataIndex: 'category',
			sorter: (a, b) => utils.antdTableSorter(a, b, 'category')
		},
		{
			title: `${strings.default.path}`,
			dataIndex: 'path',
			render: (_, record) => {
				return (
					<div className="d-flex">
						<a href={record.path}>Link</a>
					</div>
				)
			},
			sorter: (a, b) => utils.antdTableSorter(a, b, 'image')
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right">
					<EllipsisDropdown menu={dropdownMenu(elm)}/>
				</div>
			)
		}
	];
	
	const rowSelection = {
		onChange: (key, rows) => {
			setSelectedRows(rows)
			setSelectedRowKeys(key)
		}
	};

	const onSearch = e => {
		const value = e.currentTarget.value
		const searchArray = e.currentTarget.value? dataItem : dataItemBackup
		const data = utils.wildCardSearch(searchArray, value)
		setDataItem(data)
		setSelectedRowKeys([])
	}

	return (
		<Card>
			<Flex alignItems="center" justifyContent="between" mobileFlex={false}>
				<Flex className="mb-1" mobileFlex={false}>
					<div className="mr-md-3 mb-3">
						<Input placeholder="Search" prefix={<SearchOutlined />} onChange={e => onSearch(e)}/>
					</div>
				</Flex>
				<div>
					<Button onClick={onAdding} type="primary" icon={<PlusCircleOutlined />} block>{strings.default.add_images}</Button>
				</div>
			</Flex>
			<div className="table-responsive">
				<Table 
					columns={tableColumns} 
					dataSource={dataItem} 
					rowKey='_id' 
					loading={isLoading}
					// rowSelection={{
					// 	selectedRowKeys: selectedRowKeys,
					// 	type: 'checkbox',
					// 	preserveSelectedRowKeys: false,
					// 	...rowSelection,
					// }}
				/>
			</div>
		</Card>
	)
}

export default Images
