import React, { Component } from "react";
import { Input, Button, Form, Modal, Card, Col, Row,message,Image } from "antd";
import axios from 'axios'
import { strings } from 'res';

export class Tampilan extends Component {

    constructor(props){
        super(props)
        this.state = {
          homePageInd:{},
          homePageEng:{},
          visible: false,
          updateMessage:"",
          upload:{
            name: 'file',
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            headers: {
              authorization: 'authorization-text',
            },
            maxSize:1000000,
            onChange(info) {
              if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
              }
              if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            }
          }
        }
        this.handleChange = this.handleChange.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.showModal = this.showModal.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleOk = this.handleOk.bind(this);
      }
    
      showModal = () => {
        this.setState({
          visible: true,
        });
      };
    
      handleCancel = e => {
        console.log(e);
        this.setState({
          visible: false,
        });
      };
    
      handleOk = e => {
        console.log(e);
        this.setState({
          visible: false,
        });
      };
    
      componentDidMount(){
        axios.get(`${strings.url.content_url}/eventsPage/read`, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin":"*"
          }
        }).then(doc=>{
            this.setState({
                homePageInd:doc.data.eventsInd[0]
              })
        })
        axios.get(`${strings.url.content_url}/eventsPage/read`, {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin":"*"
            }
          }).then(doc=>{
              this.setState({
                  homePageEng:doc.data.eventsEng[0]
                })
          })
      }
    
      handleChange(event){
        this.setState({
          homePageInd:{
            ...this.state.homePageInd,
            [event.target.name]: event.target.value
           },
           homePageEng:{
            ...this.state.homePageEng,
            [event.target.name]: event.target.value
           },
        })
        console.log(this.state.homePageInd)
      }
    
       onFinish = (values) =>{
        axios.post(`${strings.url.content_url}/eventsPage/updateIndonesia`,this.state.homePageInd, {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin":"*"
            }
          }).then(doc=>{
            console.log(doc)
            this.setState({
              visible: true,
              updateMessage:doc.data.msg
            });
          })
          axios.post(`${strings.url.content_url}/eventsPage/updateEnglish`,this.state.homePageEng, {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin":"*"
            }
          }).then(doc=>{
            console.log(doc)
            this.setState({
              visible: true,
              updateMessage:doc.data.msg
            });
          })
      }

    render() {
        return (
            <div >
                 <Modal
                        title="Message!"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                    >
                        <h3 style={{textAlign:"center"}}>{this.state.updateMessage}</h3>
                    </Modal>
                <h1 style={{ textAlign: "center" }}>Events Page Structure</h1>
                <Card style={{ height: "100%" }}>
                    <Card>
                        <Row >
                            <h3 style={{ textAlign: "center" }}>Navigation</h3>
                        </Row>
                    </Card>
                    <Card>
                        <Row>
                            <h5>Events</h5>
                        </Row>
                        <br />
                        <Row>
                            <p>Events Desc</p>
                        </Row>
                    </Card>
                    <Card>
                        <Row gutter={24}>
                            <Col lg={16}>
                                <Card>
                                    <Row gutter={24}>
                                        <Col lg={24}><Card>Event - 1</Card></Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col lg={24}><Card>Event - 2</Card></Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col lg={24}><Card>Event - 3</Card></Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col lg={24}><Card>Event - 4</Card></Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col lg={24}><Card>Event - 5</Card></Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col lg={8}>
                                <Row gutter={24}>
                                    <Col lg={24}><Card>Search Section</Card></Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col lg={24}>
                                        <Card>
                                            <Row>Seminar</Row>
                                                <br/>
                                            <Row>Workshop</Row>
                                            <br/>
                                            <Row>Training</Row>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col lg={24}>
                                        <Card>
                                            <h5>Latest Events</h5>
                                            <br/>
                                            <Row>Event - 1</Row>
                                            <br/>
                                            <Row>Event - 2</Row>
                                            <br/>
                                            <Row>Event - 3</Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>


                    <Card>
                        <Row >
                            <h3 style={{ textAlign: "center" }}>Stakeholders Section</h3>
                        </Row>
                    </Card>
                    <Card>
                        <Row >
                            <h3 style={{ textAlign: "center" }}>Footer</h3>
                        </Row>
                    </Card>
                </Card>
                <h1>Images</h1>
                <Card style={{ height: "100%" }}>
                <Form onFinish={this.onFinish} >  
                    <label>Image - 1</label>
                    <Input onChange={this.handleChange} value={this.state.homePageInd.image_1} name="image_1" placeholder="Image - 1" />
                    <br />
                    <br />
                    <Image style={{width:"50px",height:"50px"}} src={this.state.homePageInd.image_1}></Image>
                    <br />
                    <br />
                    <Form.Item >
                        <Button htmlType="submit" type="primary" style={{width:"100%"}} >Submit</Button>
                    </Form.Item>
                </Form>
                </Card>
            </div>
        );
    }
}

export default Tampilan;
