import React, { Component } from "react";
import axios from 'axios'
import { Input,Button,Form ,Modal} from "antd";
import { strings } from 'res';
import TextArea from "antd/lib/input/TextArea";

export class Basic extends Component {
  constructor(props){
    super(props)
    this.state = {
      homePageInd:{},
      visible:false,
      updateMessage:""
    }
    this.handleChange = this.handleChange.bind(this);
    this.onFinish = this.onFinish.bind(this);
    this.showModal = this.showModal.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  componentDidMount(){
    axios.get(`${strings.url.content_url}/storiesPage/read`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin":"*"
      }
    }).then(doc=>{
      if(this.props.bahasa === "Indonesia"){
        this.setState({
          homePageInd:doc.data.storiesInd[0]
        })
      }else{
        this.setState({
          homePageInd:doc.data.storiesEng[0]
        })
      }
     
    })
  }
  handleChange(event){
    this.setState({
      homePageInd:{
        ...this.state.homePageInd,
        [event.target.name]: event.target.value
       }
    })
  }

   onFinish = (values) =>{
    if(this.props.bahasa === "Indonesia"){
      axios.post(`${strings.url.content_url}/storiesPage/updateIndonesia`,this.state.homePageInd, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin":"*"
        }
      }).then(doc=>{
        this.setState({
          visible: true,
          updateMessage:doc.data.msg
        });
      })
    }else{
      axios.post(`${strings.url.content_url}/storiesPage/updateEnglish`,this.state.homePageInd, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin":"*"
        }
      }).then(doc=>{
        this.setState({
          visible: true,
          updateMessage:doc.data.msg
        });
      })
    }
  }
  render() {
    return (
      <div>
        <Modal
          title="Message!"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <h3 style={{textAlign:"center"}}>{this.state.updateMessage}</h3>
        </Modal>
        <h1>{this.props.bahasa}</h1>
        <Form onFinish={this.onFinish}>
        <label>Stories</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.stories} name="stories" placeholder="Stories" />
        <br />
        <br />
        <label>Stories Desc</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.stories_desc} name="stories_desc" placeholder="Stories Description" />    
        <br />
        <br />
        <label>Impact</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.impact} name="impact" placeholder="Impact" />
        <br />
        <br />
        <label>Impact Desc</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.impact_desc} name="impact_desc" placeholder="Impact Description" />
        <br />
        <br />
        <label>News</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.news} name="news" placeholder="News" />
        <br />
        <br />
        <label>News Desc</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.news_desc} name="news_desc" placeholder="News Description" />
        <br />
        <br />
        <label>Media</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.media} name="media" placeholder="Media" />
        <br />
        <br />
        <label>Media Desc</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.media_desc} name="media_desc" placeholder="Media Description" />
        <br />
        <br />
        <label>Kategori</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.kategori} name="kategori" placeholder="Kategori" />
        <br />
        <br />
        <label>Latest Stories</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.latest_stories} name="latest_stories" placeholder="Latest Stories" />
        <br />
        <br />
        <label>Button</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.button} name="button" placeholder="Button" />
        <br />
        <br />
        <Form.Item >
          <Button htmlType="submit" type="primary" style={{width:"100%"}} >Submit</Button>
        </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Basic;
