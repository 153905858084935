import React, { Component } from 'react';
import { Row, Col } from 'antd';
import Basic from './Basic';
import Tampilan from './Tampilan';

export class InputComponent extends Component {
  render() {
    return (
      <div className="code-box-demo-input">
        <Row gutter={24} type="flex">
			<Col sm={24} md={24} lg={12}>
				<Tampilan/>
			</Col>
			<Col sm={24} md={24} lg={6}>
				<Basic bahasa="Indonesia"></Basic>
			</Col>
			<Col sm={24} md={24} lg={6}>
				<Basic bahasa="English"></Basic>
			</Col>  
        </Row>
      </div>
    );
  }
}

export default InputComponent;
