import React, {useEffect} from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from '../lang';
import jwt_decode from 'jwt-decode'
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { strings } from "res";
// import PrivateRoute from "components/PrivateRoute";

export const Views = (props) => {
  const { locale } = props;
  const currentAppLocale = AppLocale[locale];

  useEffect(()=>{
    // Check for token to keep user logged in
    if (localStorage.getItem('token') && localStorage.getItem('token') !== "null") {
        // Set auth token header auth
        const token = localStorage.getItem('token');
        const decoded = jwt_decode(token);
        const currentTime = Date.now() / 1000; // to get in milliseconds
        console.log(decoded.exp < currentTime)
        console.log(decoded.exp)
        console.log(currentTime)
        if (decoded.exp < currentTime) {
            // Logout user
            localStorage.clear()
            // Redirect to login
            window.location.href = "/auth";
        } 
    }else{
      localStorage.clear()
    }
  },[])

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd}>
        <Switch>
          <Route exact path="/">
            <Redirect to={strings.navigation.login} />
          </Route>
          <Route path="/auth">
            <AuthLayout />
          </Route>
          <Route path="/app">
            <AppLayout />
          </Route>
        </Switch>
      </ConfigProvider>
      </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale } =  theme;
  const { token } = auth;
  return { locale, token }
};

export default withRouter(connect(mapStateToProps)(Views));