import { Button, Card, Col, Form, Input, message, Row, Select } from 'antd';
import TextArea from "antd/lib/input/TextArea";
import confirm from 'antd/lib/modal/confirm';
import Axios from 'axios';
import { PageHeaderAlt } from 'components/layout-components/PageHeaderAlt';
import Flex from 'components/shared-components/Flex';
import React, { useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useHistory, useLocation } from 'react-router-dom';
import { dimens, strings } from 'res';

const AddTicket = () => {

    const rules = {
        name: [
            {
                required: true,
                message: 'Please enter title',
            }
        ],
        website: [
            {
                required: true,
                message: 'Please enter tiket',
            }
        ]
    }

    let history = useHistory();
    const [form] = Form.useForm();
    const location = useLocation();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [uploadedImg, setImage] = useState('')
    const [editorState, setEditorState] = useState(); 
    const [editorStateEn, setEditorStateEn] = useState();
    const [idItem, setIdItem] = useState();
    const [data,setData] = useState({
        _id:"",
        title:"",
        date_string:"",
        timestamp:"",
        desc:"",
        detail:"",
        status:"Active"
    })

    useEffect(() => {
        if (location.state.id) {
            getDataDetail(location.state.id)
        } else {
            setEditorState({"entityMap":{},"blocks":[{"key":"637gr","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]})
            setEditorStateEn({"entityMap":{},"blocks":[{"key":"637gr","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]})
        }
    }, []) 

    const getDataDetail = (value) => {
        console.log(value)
        setIdItem(value._id);
        form.setFieldsValue({
            title: value.title,
            desc: value.desc,
            email: value.email
        })
        setData({
            ...data,
            email:value.email,
            _id:value._id
        })
    }


    const onUpdate = (values) => {
        setSubmitLoading(true)
        let formData = new FormData()
        formData.append('title', values.title)
        formData.append('desc', values.desc)
        formData.append('detail', values.detail)
        formData.append('id', data._id)
        Axios.post(`${strings.url.content_url}/message/update`, {
            title:values.title,
            desc:values.desc,
            detail:values.detail,
            id:data._id
        }, {
            headers: {
                'content-type': 'application/json',
                "Access-Control-Allow-Origin":"*"
            }
        }).then(doc => {
            if (doc.data.error === false) {
                setSubmitLoading(false)
                message.success(`${strings.response.success}`)
                history.goBack()
            }
        }).catch(e => {
            console.log('error: ', e.message)
        })
    }

    const onFinish = () => {
        form.validateFields().then(values => {
            confirm({
                title: "You sure you wanna go back?",
                content: "Click 'Ok' to go back!",
                onOk() {
                    history.goBack()
                },
                onCancel() {},
            });
        })
    }

    const onPost = (values) => {
        setSubmitLoading(true)
        let formData = new FormData()
        formData.append('title', values.title)
        formData.append('desc', values.desc)
        formData.append('detail', values.detail)
        Axios.post(`${strings.url.content_url}/message/create`, {
            title:values.title,
            desc:values.desc,
            detail:values.detail
        }, {
            headers: {
                'content-type': 'application/json',
                "Access-Control-Allow-Origin":"*"
            }
        }).then(doc => {
            if (doc.data.error === false) {
                setSubmitLoading(false)
                message.success(`${strings.response.success}`)
                history.goBack()
            }
        }).catch(e => {
            console.log('error: ', e.message)
        })
    }

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            name="advanced_search"
            className="ant-advanced-search-form"
        >
            <PageHeaderAlt className="bg-white border-bottom" overlap>
                <div className="container">
                    <Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
                        <h2 className="mb-3">{strings.default.en.add_resources}</h2>
                        <div className="mb-3">
                            <Button type="primary" htmlType="submit" loading={submitLoading} >
                                Go Back
                            </Button>
                        </div>
                    </Flex>
                </div>
            </PageHeaderAlt>
            <div className="container" style={{marginTop: dimens.medium}}>
                <Col>
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={24}>
                            <Card title="Add New Ticket">
                                <Form.Item name={"title"} label={"Title"}>
                                    <Input placeholder={"Title"} />
                                </Form.Item>
                                <Form.Item name={"email"} label={"Email"}>
                                    <Input placeholder="Email" />
                                </Form.Item>
                                <Form.Item name={"desc"} label={"Desc"} label="Detail">
                                    <TextArea rows={15} placeholder="Message Description" />
                                </Form.Item>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </div>
        </Form>
    )
}

export default AddTicket
