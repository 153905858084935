import React, {useState,useEffect} from 'react'
import { Card, Table, Select, Input, Button, Badge, Menu } from 'antd';
import { EyeOutlined, DeleteOutlined, SearchOutlined, PlusCircleOutlined } from '@ant-design/icons';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import Flex from 'components/shared-components/Flex'
import NumberFormat from 'react-number-format';
import { useHistory } from "react-router-dom";
import utils from 'utils'
import axios from 'axios'
import { strings } from 'res';
import confirm from 'antd/lib/modal/confirm';

const { Option } = Select

const categories = ['Cloths', 'Bags', 'Shoes', 'Watches', 'Devices']

const ProductList = () => {
	let history = useHistory();
	const [selectedRows, setSelectedRows] = useState([])
	const [selectedRowKeys, setSelectedRowKeys] = useState([])

	const dropdownMenu = row => (
		<Menu>
			<Menu.Item onClick={() => viewDetails(row)}>
				<Flex alignItems="center">
					<EyeOutlined />
					<span className="ml-2">View Details</span>
				</Flex>
			</Menu.Item>
			<Menu.Item onClick={() => deleteRow(row)}>
				<Flex alignItems="center">
					<DeleteOutlined />
					<span className="ml-2">{selectedRows.length > 0 ? `Delete (${selectedRows.length})` : 'Delete'}</span>
				</Flex>
			</Menu.Item>
		</Menu>
	);
	
	const addProduct = () => {
		history.push(`/app/webimages/add-product`)
		// window.location.href = "/app/webimages/add-product";
	}
	
	const viewDetails = row => {
		history.push(`/app/webimages/edit-product/${row.id}`)
		// window.location.href = `/app/webimages/add-product/${row.id}`;
	}
	
	const deleteRow = row => {
		confirm({
			title: strings.default.confirmation.is_confirmation,
			content: strings.default.confirmation.is_delete,
			onOk() {
				onDelete(row);
			},
			onCancel() {},
		});
	}

	const onDelete = (row) => {
		setIsLoading(true)
		axios.get(`${strings.url.content_url}/webimages/delete/${row._id}`, { headers: {
			'Content-Type': 'application/json'
		  }}).then(doc => {
			  console.log(doc)
			if(doc.data !== null){
				getData();
				setIsLoading(false);
			}
		}).catch(e => {
			console.log(e.message)
		})
	}

	const tableColumns = [
		{
			title: 'ID',
			dataIndex: '_id'
		},
		{
			title: 'Name',
			dataIndex: 'name',
			render: (_, record) => (
				<div className="d-flex">
					<AvatarStatus size={60} type="square" src={record.path} name={record.name}/>
				</div>
			),
			sorter: (a, b) => utils.antdTableSorter(a, b, 'name')
		},
		{
			title: 'Category',
			dataIndex: 'category',
			sorter: (a, b) => utils.antdTableSorter(a, b, 'category')
		},
		{
			title: 'Value Chains',
			dataIndex: 'value_chains',
			// render: price => (
			// 	<div>
			// 		<NumberFormat
			// 			displayType={'text'} 
			// 			value={(Math.round(price * 100) / 100).toFixed()} 
			// 			prefix={'px '} 
			// 			thousandSeparator={true} 
			// 		/>
			// 	</div>
			// ),
			sorter: (a, b) => utils.antdTableSorter(a, b, 'value_chains')
		},
		{
			title: 'Video URL',
			dataIndex: 'video_link',
			render: link => (
				<div>
					<a href={link} target="_blank">Link</a>
				</div>
			),
			sorter: (a, b) => utils.antdTableSorter(a, b, 'video_link')
		},
		{
			title: 'Path',
			dataIndex: 'path',
			render: image => (
				<a href={image} target="_blank">Link</a>
			),
			sorter: (a, b) => utils.antdTableSorter(a, b, 'path')
		},
		{
			title: '',
			dataIndex: '_id',
			render: (_, elm) => (
				<div className="text-right">
					<EllipsisDropdown menu={dropdownMenu(elm)}/>
				</div>
			)
		}
	];
	
	const onSearch = e => {
		const value = e.currentTarget.value
		const searchArray = e.currentTarget.value? dataItem : dataItemBackup
		const data = utils.wildCardSearch(searchArray, value)
		setDataItem(data)
		setSelectedRowKeys([])
	}

	const handleShowCategory = value => {
		if(value !== 'All') {
			let res = dataItem.filter(item => item.id_categories === value)
			setDataItemBackup(dataItem)
			setDataItem(res)
		} else {
			setDataItem(dataItemBackup)
		}
	}

	useEffect(() => {
		getData()
		getDataCategories()
	}, [])

	const [dataItem, setDataItem] = useState([]);
	const [dataItemBackup, setDataItemBackup] = useState([]);
	const [isLoadingCategory, setIsLoadingCategory] = useState(true);
	const [dataCategories, setDataCategories] = useState([])
	const [isLoading, setIsLoading] = useState(true)

	const rowSelection = {
		onChange: (key, rows) => {
			setSelectedRows(rows)
			setSelectedRowKeys(key)
		}
	};

	const getDataCategories = async () => {
        let res = await axios.get(`${strings.url.base_url}api/categories/type/2`, {
			headers: {
			  'Content-Type': 'application/json',
			}
        })
        setDataCategories(res.data);
        setIsLoadingCategory(false);
    }

	const getData = async() => {
		let response = await axios.get(`${strings.url.content_url}/webimages/read`,{
			headers:{
				'content-type': 'multipart/form-data',
				"Access-Control-Allow-Origin": "*"
			}
		},(docs)=>{
			return docs.data
		})
		setDataItemBackup(response.data.doc)
		setDataItem(response.data.doc);
		setIsLoading(false);
	}

	return (
		<Card>
			<Flex alignItems="center" justifyContent="between" mobileFlex={false}>
				<Flex className="mb-1" mobileFlex={false}>
					<div className="mr-md-3 mb-3">
						<Input placeholder="Search" prefix={<SearchOutlined />} onChange={e => onSearch(e)}/>
					</div>
					<div className="mb-3">
						<Select 
							defaultValue="All" 
							className="w-100" 
							style={{ minWidth: 180 }} 
							onChange={handleShowCategory} 
							placeholder="Category"
						>
							<Option value="All">All</Option>
							{
								categories.map(elm => (
									<Option key={elm} value={elm}>{elm}</Option>
								))
							}
						</Select>
					</div>
				</Flex>
				<div>
					<Button onClick={addProduct} type="primary" icon={<PlusCircleOutlined />} block>Add Image</Button>
				</div>
			</Flex>
			<div className="table-responsive">
				<Table 
					columns={tableColumns} 
					dataSource={dataItem} 
					rowKey='_id' 
					rowSelection={{
						selectedRowKeys: selectedRowKeys,
						type: 'checkbox',
						preserveSelectedRowKeys: false,
						...rowSelection,
					}}
				/>
			</div>
		</Card>
	)
}

export default ProductList
