import {
  LogoutOutlined, QuestionCircleOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { Dropdown, Menu } from "antd";
import Icon from 'components/util-components/Icon';
import React from "react";
import { connect } from 'react-redux';
import { signOut } from 'redux/actions/Auth';
import { strings } from 'res';
import {useHistory} from 'react-router-dom'

const menuItem = [    
    {
      title: `${strings.menu.accountSetting}`,
      icon: SettingOutlined,
      path: `/app/${strings.navigation.path.accountProfile}`
    },
    {
      title: `${strings.menu.helpCenter}`,
      icon: QuestionCircleOutlined,
      path: "/"
	}
]

export const NavProfile = ({signOut}) => {

  const history = useHistory()
  
  const logout = () =>{
    localStorage.clear()
    history.push("/auth/login")
  }

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.legth + 1} onClick={logout}>
            <span>
              <LogoutOutlined onClick={logout} className="mr-3"/>
              <span onClick={logout} className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <SettingOutlined />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default connect(null, {signOut})(NavProfile)
