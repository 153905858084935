import { DeleteOutlined, EyeOutlined, PlusCircleOutlined, SearchOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Input, Menu, Select, Table } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import Axios from 'axios';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import Flex from 'components/shared-components/Flex';
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { strings } from 'res';
import utils from 'utils';

const { Option } = Select

const Resources = (props) => {
	let history = useHistory();
	const [selectedRows, setSelectedRows] = useState([])
	const [selectedRowKeys, setSelectedRowKeys] = useState([])
	const [dataItem, setDataItem] = useState([]);
	const [dataItemBackup, setDataItemBackup] = useState([]);
	const [isLoading, setIsLoading] = useState(true)
	
	const dropdownMenu = row => (
		<Menu>
			<Menu.Item onClick={() => onUpdate(row)}>
				<Flex alignItems="center">
					<EditOutlined />
					<span className="ml-2"> {strings.option.update} </span>
				</Flex>
			</Menu.Item>
			<Menu.Item onClick={() => deleteRow(row)}>
				<Flex alignItems="center">
					<DeleteOutlined />
					<span className="ml-2">{selectedRows.length > 0 ? `Delete (${selectedRows.length})` : strings.option.remove}</span>
				</Flex>
			</Menu.Item>
		</Menu>
	);
	
	const addResources = () => {
		history.push({
			pathname: strings.navigation.page.addResources,
			state: {
				param: strings.option.add
			}
		})
	}
	
	const onUpdate = row => {
		history.push({
			pathname: strings.navigation.page.addResources,
			state: {
				param: strings.option.update,
				id: row
			}
		})
	}

	const deleteRow = row => {
		confirm({
			title: strings.default.confirmation.is_confirmation,
			content: strings.default.confirmation.is_delete,
			onOk() {
				onDelete(row);
			},
			onCancel() {},
		});
	}

	const onDelete = (row) => {
		setIsLoading(true)
		Axios.get(`${strings.url.content_url}/resource/delete/${row._id}`, { headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${strings.url.token}`
		  }}).then(doc => {
			if(doc.data !== null){
				getData();
				setIsLoading(false);
			}
		}).catch(e => {
			console.log(e.message)
		})
	}

	const tableColumns = [
		{
			title: `${strings.default.en.image}`,
			dataIndex: 'resources_thumbnail',
			render: (_, record) => {
				return (
					<div className="d-flex">
						<AvatarStatus size={60} type="square" src={record.resources_thumbnail}/>
					</div>
			)},
			sorter: (a, b) => utils.antdTableSorter(a, b, 'resources_thumbnail')
		},
		{
			title: `${strings.default.en.title}`,
			dataIndex: 'title',
			sorter: (a, b) => utils.antdTableSorter(a, b, 'title')
		},
		{
			title: `${strings.default.en.categories}`,
			dataIndex: 'category'
		},
		{
			title: `${strings.default.en.created_date}`,
			dataIndex: 'date_string',
			sorter: (a, b) => utils.antdTableSorter(a, b, 'date_string')
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right">
					<EllipsisDropdown menu={dropdownMenu(elm)}/>
				</div>
			)
		}
	];

	const onSearch = e => {
		const value = e.currentTarget.value
		const searchArray = e.currentTarget.value? dataItem : dataItemBackup
		const data = utils.wildCardSearch(searchArray, value)
		setDataItem(data)
		setSelectedRowKeys([])
	}

	const handleShowCategory = value => {
		if(value !== 'All') {
			let res = dataItem.filter(item => item.id_categories == value)
			setDataItemBackup(dataItem)
			setDataItem(res)
		} else {
			setDataItem(dataItemBackup)
		}
	}

	useEffect(() => {
		getData()
	}, [])

	const getData = async() => {
		//let res = await Axios.get(`${strings.url.base_url}/resource/read`, {})
		let res = await Axios.get(`${strings.url.content_url}/resource/read`, {})
		setDataItemBackup(res.data.docs)
		setDataItem(res.data.docs);
		setIsLoading(false);
	}

	return (
		<Card>
			<Flex alignItems="center" justifyContent="between" mobileFlex={false}>
				<Flex className="mb-1" mobileFlex={false}>
					<div className="mr-md-3 mb-3">
						<Input placeholder="Search" prefix={<SearchOutlined />} onChange={e => onSearch(e)}/>
					</div>
					<div className="mb-3">
						<Select 
							defaultValue="All" 
							className="w-100" 
							style={{ minWidth: 180 }} 
							onChange={handleShowCategory} 
							placeholder="Category"
						>
							<Option value="All">All</Option>
						
						</Select>
					</div>
				</Flex>
				<div>
					<Button onClick={() => addResources()} type="primary" icon={<PlusCircleOutlined />} block>{strings.default.add_resources}</Button>
				</div>
			</Flex>
			<div className="table-responsive">
				<Table
					loading={isLoading} 
					columns={tableColumns} 
					dataSource={dataItem} 
					rowKey='_id' 
				/>
			</div>
		</Card>
	)
}

export default Resources

