import React, { Component } from "react";
import axios from 'axios'
import { Input,Button,Form,Modal } from "antd";
import { strings } from 'res';
import TextArea from "antd/lib/input/TextArea";

export class Basic extends Component {
  constructor(props){
    super(props)
    this.state = {
      homePageInd:{},
      visible:false,
      updateMessage:""
    }
    this.handleChange = this.handleChange.bind(this);
    this.onFinish = this.onFinish.bind(this);
    this.showModal = this.showModal.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  componentDidMount(){
    axios.get(`${strings.url.content_url}/eventsPage/read`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin":"*"
      }
    }).then(doc=>{
      if(this.props.bahasa === "Indonesia"){
        this.setState({
          homePageInd:doc.data.eventsInd[0]
        })
      }else{
        this.setState({
          homePageInd:doc.data.eventsEng[0]
        })
      }
     
    })
  }

  handleChange(event){
    this.setState({
      homePageInd:{
        ...this.state.homePageInd,
        [event.target.name]: event.target.value
       }
    })
  }

   onFinish = (values) =>{
    console.log(this.state)
    if(this.props.bahasa === "Indonesia"){
      axios.post(`${strings.url.content_url}/eventsPage/updateIndonesia`,this.state.homePageInd, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin":"*"
        }
      }).then(doc=>{
        this.setState({
          visible: true,
          updateMessage:doc.data.msg
        });
      })
    }else{
      axios.post(`${strings.url.content_url}/eventsPage/updateEnglish`,this.state.homePageInd, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin":"*"
        }
      }).then(doc=>{
        this.setState({
          visible: true,
          updateMessage:doc.data.msg
        });
      })
    }
  }
  render() {
    return (
      <div>
         <Modal
          title="Message!"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <h3 style={{textAlign:"center"}}>{this.state.updateMessage}</h3>
        </Modal>
        <h1>{this.props.bahasa}</h1>
        <Form onFinish={this.onFinish}>
        <label>Events Header</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.header} name="header"  placeholder="Header" />
        <br />
        <br />
        <label>Events Description</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.header_desc} name="header_desc" placeholder="Header Description" />    
        <br />
        <br />
        <label>Seminar Label</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.seminar} name="seminar" placeholder="Seminar" />
        <br />
        <br />
        <label>Seminar Description</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.seminar_desc} name="seminar_desc" placeholder="Seminar Description" />
        <br />
        <br />
        <label>Workshop Label</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.workshop} name="workshop" placeholder="Workshop" />
        <br />
        <br />
        <label>Workshop Description</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.workshop_desc} name="workshop_desc" placeholder="Workshop Description" />
        <br />
        <br />
        <label>Training Label</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.training} name="training" placeholder="Training" />
        <br />
        <br />
        <label>Training Description</label><br/><label style={{color:"red"}}>(Recommended Max 100 Words)</label>
        <TextArea rows={10} onChange={this.handleChange} value={this.state.homePageInd.training_desc} name="training_desc" placeholder="Training Description" />
        <br />
        <br />
        <label>Latest Events Label</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.latest_events} name="latest_events" placeholder="Latest Events" />
        <br />
        <br />
        <label>Button Text</label><br/><label style={{color:"red"}}>(Recommended Max 10 Words)</label>
        <Input onChange={this.handleChange} value={this.state.homePageInd.button} name="button" placeholder="Button" />
        <br />
        <br />
        <Form.Item >
          <Button htmlType="submit" type="primary" style={{width:"100%"}} >Submit</Button>
        </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Basic;
