import { Button, Card, Col, Form, Input, message, Row, Select, Image } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import confirm from 'antd/lib/modal/confirm';
import Axios from 'axios';
import { PageHeaderAlt } from 'components/layout-components/PageHeaderAlt';
import Flex from 'components/shared-components/Flex';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useHistory, useLocation } from 'react-router-dom';
import { dimens, strings } from 'res';

const AddStories = () => {

    const [links, setLinks] = useState([]);
    const [dataImage, setDataImage] = useState({});
    const [count, setCount] = useState(0);

    const rules = {
        name: [
            {
                required: true,
                message: 'Please enter title',
            }
        ],
        website: [
            {
                required: true,
                message: 'Please enter website',
            }
        ],
        time: [
            {
                required: false,
            }
        ],
        date: [
            {
                required: false,
            }
        ],
        comparePrice: [
        ],
        taxRate: [
            {
                required: true,
                message: 'Please enter tax rate',
            }
        ],
        cost: [
            {
                required: true,
                message: 'Please enter item cost',
            }
        ]
    }

    const { Option, OptGroup } = Select;
    let history = useHistory();
    const [form] = Form.useForm();
    const location = useLocation();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [dataCategories, setDataCategories] = useState(strings.category.stories);
    const [uploadedImg, setImage] = useState('')
    const [editorState, setEditorState] = useState();
    const [editorStateEn, setEditorStateEn] = useState();
    const [defaultCategories, setDefaultCategories] = useState('')
    const [defaultImg, setDefaultImg] = useState('')
    const [idItem, setIdItem] = useState();
    const [htmlValue, setHtmlValue] = useState("")
    const [htmlEnValue, setHtmlEnlValue] = useState("")

    useEffect(() => {
        if (location.state.param === strings.option.update) {
            getDataDetail(location.state.id)
        } else {
            setDefaultImg([])
            setDefaultCategories([])
            setEditorState({ "entityMap": {}, "blocks": [{ "key": "637gr", "text": "", "type": "unstyled", "depth": 0, "inlineStyleRanges": [], "entityRanges": [], "data": {} }] })
            setEditorStateEn({ "entityMap": {}, "blocks": [{ "key": "637gr", "text": "", "type": "unstyled", "depth": 0, "inlineStyleRanges": [], "entityRanges": [], "data": {} }] })
        }
    }, [])

    // const getDataDetail = (value) => {
    //     console.log(value.html)
    //     setIdItem(value._id);
    //     SpreadUpdateData(JSON.parse(value.images))
    //     setLinks(JSON.parse(value.images))
    //     form.setFieldsValue({
    //         title_idn: value.title,
    //         title_en: value.title_en,
    //         description_idn: value.desc,
    //         description_en: value.desc_en,
    //         images:value.images || [],
    //         author: value.author,
    //         html_value:value.html,
    //         html_value_en:value.html_en,
    //         Categories: value.category.split(',')
    //     })
    //     setDefaultCategories(value.category.split(','));
    //     setEditorState(JSON.parse(value.markdown))
    //     setEditorStateEn(JSON.parse(value.markdown_en))
    //     setDefaultImg([value.stories_thumbnail])
    //     setHtmlEnlValue(value.html_en)
    //     setHtmlValue(value.html)
    // }

    const getDataDetail = (value) => {
        if (value._id) {
            Axios.get(`${strings.url.content_url}/stories/readById/${value._id}`, {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            }).then(doc => {
                console.log(doc.data.docs)
                let value = doc.data.docs
                setIdItem(value._id);
                SpreadUpdateData(JSON.parse(value.images))
                setLinks(JSON.parse(value.images))
                form.setFieldsValue({
                    title_idn: value.title,
                    title_en: value.title_en,
                    description_idn: value.desc,
                    description_en: value.desc_en,
                    images: value.images,
                    video_link: value.video_link,
                    news_link: value.news_link,
                    author: value.author,
                    html_value: value.html,
                    html_value_en: value.html_en,
                    Categories: value.category.split(',')
                })
                setDefaultCategories(value.category.split(','));
                setEditorState(JSON.parse(value.markdown))
                setEditorStateEn(JSON.parse(value.markdown_en))
                setDefaultImg([value.stories_thumbnail])
                setHtmlEnlValue(value.html_en)
                setHtmlValue(value.html)
            })
        }
    }

    //Markdown
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    }

    const onEditorStateChangeEn = (editorState) => {
        setEditorStateEn(editorState);
    }

    const tambahLink = () => {
        let counts = count + 1
        setCount(counts)
        setLinks([...links, { id: count, value: "" }])
        console.log(links)
    }

    const SpreadUpdateData = (array) => {
        console.log(array)
        array.map((data, row) => {
            dataImage[`images-${row}`] = data.image
            dataImage[`videos-${row}`] = data.video
            dataImage[`images_description-${row}`] = data.images_description
            dataImage[`images_description_eng-${row}`] = data.images_description_eng
        })
    }

    const handleImagesChange = (event) => {
        setDataImage({
            ...dataImage,
            [event.target.name]: event.target.value
        })
    }

    const deleteItem = (index) => {
        const filtered = links.filter((link, idx) => idx !== index)
        delete dataImage[`images-${index}`]
        delete dataImage[`videos-${index}`]
        delete dataImage[`images_description-${index}`]
        delete dataImage[`images_description_eng-${index}`]
        setLinks(filtered)
    }

    const renderLinks = () => {
        return links.map((row, idx) => {
            return (
                <div key={idx}>
                    <br />
                    <Row gutter={24} style={{ border: "1px" }}>
                        <Col md={3}>
                            <label>Photo-{idx}</label>
                        </Col>
                        <Col md={15}>
                            <label>URL</label>
                            <Input idx={idx} value={dataImage[`images-${idx}`]} onChange={handleImagesChange} name={`images-${idx}`} placeholder="Image URL"></Input>
                            <br />
                            <br />
                            <label>Video Link (type </label><label style={{ color: "red" }}>"No_Video"</label><label> if there is no video) Youtube Video Code (Example: 3T0UZNBqMk4)</label>
                            <Input idx={idx} value={dataImage[`videos-${idx}`]} onChange={handleImagesChange} name={`videos-${idx}`} placeholder="Video URL"></Input>
                            <br />
                            <br />
                            <label>Deskripsi (Indonesia)</label>
                            <TextArea idx={idx} value={dataImage[`images_description-${idx}`]} onChange={handleImagesChange} rows={2} name={`images_description-${idx}`} placeholder="Deskripsi"></TextArea>
                            <br />
                            <br />
                            <label>Description (English)</label>
                            <TextArea idx={idx} value={dataImage[`images_description_eng-${idx}`]} onChange={handleImagesChange} rows={2} name={`images_description_eng-${idx}`} placeholder="Description"></TextArea>
                        </Col>
                        <Col md={6}>
                            <Button onClick={() => deleteItem(idx)} type="danger" style={{ color: "#FFF" }}>Delete</Button>
                            <br></br>
                            <br></br>
                            <Image src={dataImage[`images-${idx}`]}></Image>
                        </Col>
                    </Row>
                </div>
            )
        })
    }

    const onUpdate = (values) => {
        setSubmitLoading(true)
        let images = []
        let count = 0
        let iterate = 0
        for (var key in dataImage) {
            if (dataImage.hasOwnProperty(key)) {
                iterate++
                if (iterate === 4) {
                    images = [
                        ...images,
                        {
                            image: dataImage[`images-${count}`],
                            video: dataImage[`videos-${count}`],
                            images_description: dataImage[`images_description-${count}`],
                            images_description_eng: dataImage[`images_description_eng-${count}`]
                        }
                    ]
                    count++
                    iterate = 0
                }
            }

        }
        let formData = new FormData()
        let date = new Date();
        formData.append('title', values.title_idn)
        formData.append('title_en', values.title_en)
        formData.append('author', values.author)
        formData.append('category', values.Categories)
        formData.append('video_link', values.video_link)
        formData.append('news_link', values.news_link)
        formData.append('images', JSON.stringify(images))
        formData.append('stories_thumbnail', uploadedImg.file)
        formData.append('date_string', `${date.getDay()} ${strings.month[date.getMonth()]} ${date.getFullYear()}`)
        formData.append('markdown', JSON.stringify(editorState))
        formData.append('markdown_en', JSON.stringify(editorStateEn))
        formData.append('html', values.html_value)
        formData.append('html_en', values.html_value_en)
        formData.append('desc', values.description_idn)
        formData.append('desc_en', values.description_en)
        Axios.put(`${strings.url.content_url}/stories/update/${idItem}`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
                "Access-Control-Allow-Origin": "*"
            }
        }).then(doc => {
            if (doc.data.error === false) {
                setSubmitLoading(false)
                message.success(`${strings.response.success}`)
                history.goBack()
            }
        }).catch(e => {
            console.log('error: ', e.message)
        })
    }

    const onFinish = () => {
        form.validateFields().then(values => {
            if (values.Categories == null) message.error(strings.response.ischoose_categories);
            else {
                if (uploadedImg === '') message.error(strings.response.isuploaded_img);
                else {
                    confirm({
                        title: strings.default.confirmation.is_confirmation,
                        content: strings.default.confirmation.is_add,
                        onOk() {
                            if (location.state.param === strings.option.update) onUpdate(values);
                            else onPost(values);
                        },
                        onCancel() { },
                    });
                }
            }
        })
    }

    const onPost = (values) => {
        setSubmitLoading(true)
        let images = []
        let count = 0
        let iterate = 0
        for (var key in dataImage) {
            if (dataImage.hasOwnProperty(key)) {
                iterate++
                if (iterate === 4) {
                    images = [
                        ...images,
                        {
                            image: dataImage[`images-${count}`],
                            video: dataImage[`videos-${count}`],
                            images_description: dataImage[`images_description-${count}`],
                            images_description_eng: dataImage[`images_description_eng-${count}`]
                        }
                    ]
                    count++
                    iterate = 0
                }
            }

        }

        let formData = new FormData()
        let date = new Date();
        formData.append('title', values.title_idn)
        formData.append('title_en', values.title_en)
        formData.append('author', values.author)
        formData.append('category', values.Categories)
        formData.append('video_link', values.video_link || "")
        formData.append('news_link', values.news_link || "")
        formData.append('images', JSON.stringify(images))
        formData.append('stories_thumbnail', uploadedImg.file)
        formData.append('date_string', `${date.getDay()} ${strings.month[date.getMonth()]} ${date.getFullYear()}`)
        formData.append('markdown', JSON.stringify(editorState))
        formData.append('markdown_en', JSON.stringify(editorStateEn))
        formData.append('html', values.html_value || "")
        formData.append('html_en', values.html_value_en || "")
        formData.append('desc', values.description_idn)
        formData.append('desc_en', values.description_en)
        Axios.post(`${strings.url.content_url}/stories/create`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
                "Access-Control-Allow-Origin": "*"
            }
        }).then(doc => {
            if (doc.data.error === false) {
                setSubmitLoading(false)
                message.success(`${strings.response.success}`)
                history.goBack()
            }
        }).catch(e => {
            console.log('error: ', e.message)
            message.error(e.message)
        })
    }

    const handleChange = (value) => { }

    const handleUploadChanged = (files) => {
        if (files.length > 0) {
            setImage({ id: files[0].name, file: files[0] })
            //setDefaultImg([info.file])
        }
    }

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            name="advanced_search"
            className="ant-advanced-search-form"
        >
            <PageHeaderAlt className="bg-white border-bottom" overlap>
                <div className="container">
                    <Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
                        <h2 className="mb-3">{strings.default.en.add_resources}</h2>
                        <div className="mb-3">
                            <Button className="mr-2" onClick={() => history.goBack()}> {strings.default.en.discard} </Button>
                            <Button type="primary" htmlType="submit" loading={submitLoading} >
                                {strings.default.en.save}
                            </Button>
                        </div>
                    </Flex>
                </div>
            </PageHeaderAlt>
            <div className="container" style={{ marginTop: dimens.medium }}>
                <Col>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12}>
                            <Card title={strings.default.indonesia}>
                                <Form.Item name={strings.default.id.title.name} label={strings.default.id.title.label} rules={rules.name}>
                                    <Input placeholder={strings.default.id.title.label} />
                                </Form.Item>
                                <Form.Item name={strings.default.id.desc.name} label={strings.default.id.desc.label} rules={rules.name}>
                                    <Input placeholder="Deskripsi" />
                                </Form.Item>
                                <Form.Item name={strings.default.id.markdown.name} label={strings.default.id.markdown.label}>
                                    {editorState && (
                                        <Card>
                                            <Editor
                                                initialContentState={editorState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onContentStateChange={onEditorStateChange}
                                                editorStyle={{ height: dimens.markdown_height }}
                                            />
                                        </Card>

                                    )}
                                </Form.Item>
                                <Form.Item value={htmlValue} name={"html_value"} label="HTML" >
                                    <TextArea value={form.html} rows={15} placeholder="Republish HTML" />
                                </Form.Item>
                            </Card>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Card title={strings.default.english}>
                                <Form.Item name={strings.default.en.title_.name} label={strings.default.en.title_.label} rules={rules.name}>
                                    <Input placeholder={strings.default.en.title_.label} />
                                </Form.Item>
                                <Form.Item name={strings.default.en.desc.name} label={strings.default.en.desc.label} rules={rules.name}>
                                    <Input placeholder={strings.default.en.desc.label} />
                                </Form.Item>
                                <Form.Item name={strings.default.en.markdown.name} label={strings.default.en.markdown.label}>
                                    {editorStateEn && (
                                        <Card>
                                            <Editor
                                                initialContentState={editorStateEn}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onContentStateChange={onEditorStateChangeEn}
                                                editorStyle={{ height: dimens.markdown_height }}
                                            />
                                        </Card>
                                    )}
                                </Form.Item>
                                <Form.Item value={htmlEnValue} name={"html_value_en"} label="HTML" >
                                    <TextArea rows={15} placeholder="Republish HTML" />
                                </Form.Item>
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={24}>
                            <Card>
                                <h5>* If you put a single link or more, the system will automatically recognize this story's type as "Photo Stories"</h5>
                                <Button type="primary" style={{ width: "100%" }} onClick={tambahLink}>Add Photo and Description</Button>
                                {renderLinks()}
                            </Card>
                        </Col>
                    </Row>
                    <Card title={strings.default.additional_info}>
                        <Form.Item name={strings.default.author.name} label={strings.default.author.label} rules={rules.name}>
                            <Input placeholder={strings.default.author.label} />
                        </Form.Item>
                        <Form.Item name={"news_link"} label={"Redirect Link (Optional, only if you want this story redirect to another link)"} >
                            <Input placeholder="News Link" />
                        </Form.Item>
                        <Form.Item name={"video_link"} label={"Youtube Video Code"} >
                            <Input placeholder="Youtube Video Code" />
                        </Form.Item>
                        <Form.Item name={strings.default.en.categories} label={strings.default.en.categories}>
                            {defaultCategories && (
                                <Select
                                    style={{ width: '100%' }}
                                    onChange={handleChange}
                                    mode="tags"
                                    defaultValue={defaultCategories}
                                    placeholder={strings.default.please_select}>
                                    {
                                        dataCategories.map(value => {
                                            return (<Option value={value.id} key={value.id}>{value.name}</Option>)
                                        })
                                    }
                                </Select>
                            )}
                        </Form.Item>
                        <Card title={strings.default.media_image + " (Max 1 Mb)"}>
                            <>
                                {!!defaultImg && (
                                    <DropzoneArea
                                        acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                                        onChange={handleUploadChanged}
                                        filesLimit={1}
                                        showAlerts={false}
                                        initialFiles={defaultImg}
                                    />
                                )}
                            </>
                        </Card>
                    </Card>
                </Col>
            </div>
        </Form>
    )
}

export default AddStories
