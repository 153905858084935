import { Button, Card, Form, Input, Modal, Select,Image, message } from 'antd';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { dimens, strings } from 'res';
import { useLocation,useHistory } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
const { Option } = Select;

const EditResources = () => {

    let location = useLocation();
    let history = useHistory();
    let [thumbnail, setThumbnail] = useState()
    let [resource, setResource] = useState()
    let [editorState, setEditorState] = useState()
    let [editorStateEn, setEditorStateEn] = useState()
    let [modalStatus, setModalStatus] = useState(false)
    let [msg, setMsg] = useState("")
    let [item, setItem] = useState({
        title: "",
        title_en: "",
        link: "",
        category: "Publications",
        date: "",
        resources: {},
        resources_thumbnail: {},
        date_string: "",
        markdown: "",
        markdown_en: "",
        link_url: "",
        timestamp: "",
        desc: "",
        desc_en: "",
        recent_resource:"",
        recent_resource_thumbnail:""
    })

    useEffect(() => {
        getDataDetail(location.state.id._id)
    }, [])

    const getDataDetail = (value) => {
        Axios.get(`${strings.url.content_url}/resource/readById/${value}`, {
            headers: {
                'content-type': 'multipart/form-data',
                "Access-Control-Allow-Origin":"*"
            }
        }).then(doc => {
            let updateItem = doc.data.docs
            setItem({
                ...updateItem,
                resources:{},
                resources_thumbnail:{},
                recent_resource:updateItem.resources,
                recent_resource_thumbnail:updateItem.resources_thumbnail
            })
            setEditorState(JSON.parse(updateItem.markdown))
            setEditorStateEn(JSON.parse(updateItem.markdown_en))
             }).catch(e => {
            console.log('error: ', e.message)
        })
    }

    const pickImageHandler = (event) => {
        setThumbnail(event.target.files[0])
    }

    const pickHandler = (event) => {
        setResource(event.target.files[0])
    }

    const handleCancel = e => {
        setModalStatus(false)
    };

    const handleOk = e => {
        setModalStatus(false)
    };

    //Markdown
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    }

    const onEditorStateChangeEn = (editorState) => {
        setEditorStateEn(editorState);
    }

    const onPost = (event) => {
        let formData = new FormData()
        formData.append('title', item.title)
        formData.append('title_en', item.title_en)
        formData.append('link_url', item.link_url)
        formData.append('author', item.author)
        formData.append('category', item.category)
        formData.append('resources', resource)
        formData.append('date_string', item.date_string)
        formData.append('resources_thumbnail', thumbnail)
        formData.append('markdown', JSON.stringify(editorState))
        formData.append('markdown_en', JSON.stringify(editorStateEn))
        formData.append('desc', item.desc)
        formData.append('desc_en', item.desc_en)
        Axios.put(`${strings.url.content_url}/resource/update/${item._id}`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
                "Access-Control-Allow-Origin":"*"
            }
        }).then(doc => {
            setMsg(doc.data.msg)
            // setModalStatus(true)
            history.goBack()
        }).catch(e => {
            console.log('error: ', e.message)
        })
    }

    const handleOnChange = (event) => {
        setItem({
            ...item,
            [event.target.name]: event.target.value
        })
    }

    const handleOnChangeSelect = (value) => {
        setItem({
            ...item,
            category: value
        })
    }

    return (
        <div class="row">
            <Modal
                title="Message!"
                visible={modalStatus}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <h3 style={{ textAlign: "center" }}>{msg}</h3>
            </Modal>
            <form onSubmit={onPost} >
                <div className="row">
                    <div className="col">
                        <h1>Indonesia</h1>
                        <label>Title in Indonesia</label>
                        <Input value={item.title} onChange={handleOnChange} style={{ width: "100%" }} name="title" type="text" placeholder="Title in Indonesian"></Input>
                        <br></br>
                        <br></br>
                        <label>Markdown in Indonesia</label>
                        <Form.Item name={"markdown"} >
                            {editorStateEn && (
                                <Card>
                                    <Editor
                                        initialContentState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onContentStateChange={onEditorStateChange}
                                        editorStyle={{ height: dimens.markdown_height }}
                                    />
                                </Card>
                            )}
                        </Form.Item>
                        <br />
                        <br />
                        <label>Description in Indonesia</label>
                        <Input value={item.desc} onChange={handleOnChange} name="desc" type="text" placeholder="Description"></Input>
                        <br></br>
                        <br></br>
                    </div>
                    <div className="col">
                        <h1>English</h1>
                        <label>Title in English</label>
                        <br />
                        <Input value={item.title_en} onChange={handleOnChange} style={{ width: "100%" }} name="title_en" type="text" placeholder="Title in English"></Input>
                        <br></br>
                        <br></br>
                        <label>Markdown in English</label>
                        <Form.Item name={"markdown_en"} >
                            {editorStateEn && (
                                <Card>
                                    <Editor
                                        initialContentState={editorStateEn}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onContentStateChange={onEditorStateChangeEn}
                                        editorStyle={{ height: dimens.markdown_height }}
                                    />
                                </Card>
                            )}
                        </Form.Item>
                        <br />
                        <br />
                        <label>Description in English</label>
                        <Input value={item.desc_en} onChange={handleOnChange} name="desc_en" type="text" placeholder="Description"></Input>
                    </div>
                </div>
                <label>Category</label>
                <Select value={item.category} defaultValue="Publications" onChange={handleOnChangeSelect} style={{ width: "100%" }} name="category" type="text" placeholder="Category">
                    <Option value="Publications">Publications</Option>
                    <Option value="Newsletters">Newsletters</Option>
                    <Option value="Manuals">Manuals</Option>
                </Select>
                <br></br>
                <br></br>
                <label>Thumbnail </label><label style={{color:"red"}}>(Max 1 Mb Recommended)</label>
                <Input onChange={pickImageHandler} name="resources_thumbnail" accept=".jpg,.png,.jpeg" type="file" placeholder="Thumbnail"></Input>
                <Image src={item.recent_resource_thumbnail} style={{width:"100%",marginTop:"5px"}}></Image>
                <br></br>
                <br></br>
                <label>PDF </label><label style={{color:"red"}}>(Max 1 Mb Recommended)</label>
                <Input onChange={pickHandler} name="resources" accept=".pdf" type="file" placeholder="PDF"></Input>
                <label>{item.recent_resource}</label>
                <br></br>
                <br></br>
                <label>Author</label>
                <Input value={item.author} onChange={handleOnChange} style={{ width: "100%" }} name="author" type="text" placeholder="Author"></Input>
                <br></br>
                <br></br>
                <label>Link URL</label>
                <Input value={item.link_url} onChange={handleOnChange} style={{ width: "100%" }} name="link_url" type="text" placeholder="Web Link (Optional)"></Input>
                <br></br>
                <br></br>
                <label>Date Submitted</label>
                <Input value={item.date_string} onChange={handleOnChange} name="date_string" type="date" placeholder="Date"></Input>
                <br></br>
                <br></br>
                
                <Form.Item >
                    <Button htmlType="submit" type="primary" style={{ width: "100%" }} >Submit</Button>
                </Form.Item>
            </form>
        </div>
    )
}

export default EditResources
