// ES6
import React, { useState } from 'react'
import ReactMapGL,{Marker} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {strings} from '../res/strings'
import { PinDrop } from '@material-ui/icons';

function Map(props) {
    const [viewport, setViewport] = useState({
      width: '100%',
      height: '40vw',
      latitude: -2.971061,
      longitude: 119.6282669,
      zoom: 3.5
    });

    const renderLocation = () =>{
      for(let i = 0; i < props.data.length;i++){
        return(
             <Marker latitude={Number(props.data[i].latitude)} longitude={Number(props.data[i].longitude)} >
              <PinDrop></PinDrop>
            </Marker>
        )
      }
    //   props.data.map(data=>{
    //     console.log(data)
    //     return(
    //       <Marker latitude={Number(data.latitude)} longitude={Number(data.longitude)} >
    //        <PinDrop></PinDrop>
    //      </Marker>
    //  )
    //   })
    }
  
    return (
      <ReactMapGL
      mapboxApiAccessToken={strings.MapboxApiKey}
        {...viewport}
        onViewportChange={nextViewport => setViewport(nextViewport)}
      >
         {props.data.map(data=>{
        return(
          <Marker latitude={Number(data.latitude)} longitude={Number(data.longitude)} >
           <PinDrop></PinDrop>
         </Marker>
     )
      })}
    </ReactMapGL>
    );
}

export default Map